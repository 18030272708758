<template>
  <div class="max-h-screen flex" @click="this.$emit('close-overlay')">
    <!-- Visible PDF Viewer -->
    <div class="w-2/3 overflow-auto">
      <pdf-with-highlighting
        :pdf="pdf"
        :highlightingColor="'red'"
        :document="document"
        :jumpData="jumpData"
        :searchTerm="searchTerm" />
    </div>
    <!-- Document Card -->
    <div class="w-1/3 overflow-auto">
      <div
        class="top-9 right-3 z-10 p-6 max-w-2xl bg-cwhite sticky rounded-md block self-start shadow-md justify-center items-center"
        @click.stop>
        <div class="flex items-center mb-4">
          <input
            type="text"
            v-model="searchTerm"
            :placeholder="t('file_viewer.highlight_placeholder')"
            class="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-primary focus:ring-primary block w-full rounded-md sm:text-sm focus:ring-1" />
        </div>
        <DocumentCard
          :deactivate-view-button="true"
          :deactivate-source-button="true"
          :document="document"
          @jumpToPage="handleJumpToPage"
          :enableJumpEvent="true" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import authenticatedAxios from "@/helpers/axios-wrapper";
import PDFWithHighlighting from "./PDFWithHighlighting.vue";
import getEndpoint from "@/helpers/endpoints";
import DocumentCard from "../DocumentCard.vue";
import { useI18n } from "vue-i18n";
import config from "../../customer_configs_flags/config.ts";

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
  components: {
    "pdf-with-highlighting": PDFWithHighlighting,
    DocumentCard,
  },
  data() {
    return {
      featureFlags: config,
      currentPage: 1,
      scrollPosition: 0,
      pageCount: 1,
      pdf: "",
      jumpData: null,
      searchTerm: "",
    };
  },
  computed: {
    ...mapGetters({
      page: "app/displayPage",
      document: "app/displayDocument",
      user: "auth/user",
    }),
    title() {
      return this.document.title;
    },
    pdfInput() {
      return this.document.pdfInput;
    },
    path() {
      return this.document.link;
    },
    fileName() {
      return this.path.replace(/^.*[\\/]/, "");
    },
    fileLocation() {
      return this.document.location;
    },
  },
  async mounted() {
    try {
      if (this.pdfInput != null && this.pdfInput != undefined && this.pdfInput != "") {
        this.pdf = this.pdfInput.data;
      } else if (this.pdf == null || this.pdf == undefined || this.pdf == "") {
        const encodedPath = encodeURIComponent(this.path);
        const { data } = await authenticatedAxios.get(
          getEndpoint(
            "/api/core/file" +
              (this.fileLocation === "firebase" ? "?mode=firebase" : "?mode=gcloud") +
              `&filePath=${encodedPath}`
          ),
          {
            timeout: 120000,
            headers: {
              "Content-Type": "multipart/form-data",
              "Genow-Authorization": `Bearer ${this.user.stsTokenManager.accessToken}`,
            },
            params: {
              "display-mode": "view",
            },
          }
        );
        this.pdf = data;
        this.currentPage = this.page;
      }
    } catch (error) {
      this.hidePDF();
      console.error(error);
      return;
    }
  },
  methods: {
    ...mapMutations({
      hidePDF: "app/hidePDF",
    }),
    handleJumpToPage(data) {
      const enhancedData = {
        ...data,
        timestamp: Date.now(), // needs to be enhanced to allow for deep watching
      };
      this.jumpData = enhancedData;
    },
    ...mapActions({
      download: "app/download",
    }),
  },
};
</script>
