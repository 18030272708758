<template>
  <div class="rounded-md">
    <div class="flex-row space-y-2">
      <div
        class="w-full flex flex-col px-6 py-3 rounded-lg bg-cwhite space-y-1"
        :class="{ 'bg-red-50 border border-red-200': conversation.error }">
        <div v-if="conversation.stopped">
          <div class="text-sm items-center flex py-2 px-4 text-red-600">
            <ExclamationTriangleIcon
              class="h-8 w-8 p-2 mr-2 rounded-full bg-red-200/20 font-light" />
            The generation of the answer was manually stopped.
          </div>
        </div>
        <ChatLoader
          v-else-if="
            conversation.reply === '' && !conversation.error && !conversation.finishedLoading
          " />
        <div v-if="conversation.reply" class="p-4">
          <div
            class="uppercase text-xs text-gray-400 font-bold pb-1 full flex items-center"
            :class="{ 'text-red-500': conversation.error }">
            <ExclamationTriangleIcon class="h-4 pr-2" v-if="conversation.error" />
            {{ conversation.error ? t("shared.error") : "" }}
          </div>
          <div
            :ref="conversation.id + '_answer'"
            v-on:focus="$event.target.select()"
            v-if="typeof conversation.reply === 'string'">
            <Markdown
              class="message"
              :source="
                conversation.finishedLoading ? conversation.reply : conversation.reply + ' ⬤'
              " />
            <div class="text-xs uppercase text-red-400" v-if="conversation.error">
              {{ t("chat_message.resend_request") }}:
              <font-awesome-icon
                :icon="['solid', 'rotate-right']"
                @click="this.sendRequest({ questionId: conversation.id }, $i18n.locale)"
                v-tooltip.bottom="$t('chat_message.resend_request')"
                class="hover:text-primary cursor-pointer self-start bg-transparent rounded-full pl-4"
                :class="{ 'text-gray-500': currentlyAnswering }" />
            </div>
          </div>
          <p class="text-xs text-slate-400 font-light leading-snug">
            {{ disclaimerForMode(conversation.mode) }}
          </p>
          <!--Chat sources div start-->
          <ChatMessageSources :conversation="conversation" />
        </div>
        <!--Chat answer div end-->
      </div>
      <div class="flex items-center justify-start" :id="'reply_' + conversation.id">
        <div class="w-8 h-8 rounded-full mr-3">
          <InitialAvatar initials="AI"> </InitialAvatar>
        </div>
        <span class="uppercase text-xs font-bold"> AI Wingman </span>
        <span v-if="!!conversation.mode" class="text-xs px-2 py-1 rounded-full bg-light mx-3">
          {{
            conversation.mode == ChatMode.Conversation
              ? t("chat_modes.conversation.title")
              : t("chat_modes.document.title")
          }}{{ conversation.llm ? ` (${conversation.llm})` : "" }}
        </span>
        <span
          v-if="conversation.recognizedIntents"
          class="text-xs px-2 py-1 mr-3 rounded-full bg-light"
          v-tooltip.bottom="$t('chat_message.recognized_intent_tooltip')">
          {{ conversation.recognizedIntents.join(", ") }}
        </span>
        <div v-if="conversation.finishedLoading && !conversation.error">
          <font-awesome-icon
            :icon="['far', 'clipboard']"
            @click="copy(conversation.id)"
            v-tooltip.bottom="$t('chat_message.copy_message_tooltip')"
            class="hover:text-primary cursor-pointer self-start px-1 bg-transparent rounded-full mr-2"
            :class="{ 'text-gray-500': currentlyAnswering }" />
          <font-awesome-icon
            icon="far fa-thumbs-up"
            v-tooltip.bottom="$t('chat_message.give_feedback_tooltip')"
            class="hover:text-primary cursor-pointer self-start px-1 bg-transparent rounded-full mr-0.5"
            @click="
              async () => {
                await giveFeedback({
                  feedback: {
                    thumbs_choice: 'thumbs_up',
                    id: conversation.feedback.id,
                    entry_id: conversation.entry_id,
                    chat_id: currentChatId,
                  },
                });
              }
            " />
          <font-awesome-icon
            icon="far fa-thumbs-down"
            v-tooltip.bottom="$t('chat_message.give_feedback_tooltip')"
            class="hover:text-primary cursor-pointer self-end px-1 bg-transparent rounded-full ml-0.5"
            @click="
              async () => {
                await giveFeedback({
                  feedback: {
                    thumbs_choice: 'thumbs_down',
                    id: conversation.feedback.id,
                    entry_id: conversation.entry_id,
                    chat_id: currentChatId,
                  },
                });
              }
            " />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ChatMode, Message } from "@/store/types/ChatTypes";
import InitialAvatar from "@/components/InitialAvatar.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import ChatLoader from "@/components/Chat/ChatLoader.vue";
import { ExclamationTriangleIcon } from "@heroicons/vue/24/outline";
import Markdown from "vue3-markdown-it";
import { mapMutations, mapActions, mapGetters } from "vuex";
import ChatMessageSources from "@/components/Chat/ChatMessageSources.vue";
import { useI18n } from "vue-i18n";

export default {
  name: "ChatMessageIn",
  setup() {
    const { t } = useI18n();
    return { t };
  },
  components: {
    InitialAvatar,
    "font-awesome-icon": FontAwesomeIcon,
    Markdown,
    ChatLoader,
    ChatMessageSources,
    ExclamationTriangleIcon,
  },
  props: {
    conversation: Message,
  },
  data() {
    return {
      showSources: {},
      ChatMode,
      isLoading: false,
      thumbsUpChoicesReasonOptions: ["good wording", "valuable content"],
      thumbsDownChoicesReasonOptions: ["no information", "false information", "not understandable"],
      detailedFeedback: "",
      thumbsChoiceIcons: {
        "good wording": "fa-solid fa-book-open",
        "valuable content": "fa-solid fa-lightbulb",
        "no information": "fa-solid fa-circle-question",
        "false information": "fa-solid fa-circle-exclamation",
        "not understandable": "fa-solid fa-circle-xmark",
      },
    };
  },
  computed: {
    ...mapGetters({
      mode: "chat/mode",
      currentlyAnswering: "chat/currentlyAnswering",
      currentChatId: "chat/currentChatId",
      chatHistory: "chat/chatHistory",
    }),
  },
  methods: {
    ...mapMutations({
      setNextQuestion: "chat/setNextQuestion",
      setMode: "chat/setMode",
      showFullScreenFeedback: "chat/showFullScreenFeedback",
    }),
    ...mapActions({
      giveFeedback: "chat/giveFeedback",
      sendRequest: "chat/askQuestion",
    }),
    disclaimerForMode(mode) {
      const { t } = useI18n();

      const disclaimers = [
        {
          mode: ChatMode.Conversation,
          text: t("chat_modes.conversation.disclaimer_past"),
        },
        {
          mode: ChatMode.Document,
          text: t("chat_modes.document.disclaimer_past"),
        },
      ];
      return disclaimers.find((element) => element.mode === mode).text;
    },
    copy(key) {
      let element = this.$refs[key + "_answer"];
      if (document.selection) {
        // IE
        let range = document.body.createTextRange();
        range.moveToElementText(element);
        range.select();
      } else if (window.getSelection) {
        let range = document.createRange();
        range.selectNode(element);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
      } else {
        console.error("Could not select text in element: Unsupported browser.");
        return;
      }

      if (document.execCommand) {
        document.execCommand("copy");
      } else if (window.Clipboard) {
        // Clipboard.writeText(document.getSelection().toString());
        window.Clipboard.writeText(element[0].innerText);
      } else if (window.clipboardData) {
        window.clipboardData.setData("Text", element[0].innerText);
      } else {
        console.error("Could not copy text: Unsupported browser.");
        return;
      }

      document.getSelection().empty();
    },
  },
};
</script>
