import { auth } from "../../main";
import router from "../../router";
import { signOut } from "firebase/auth";
const state = {
  user: null,
};

const getters = {
  user: (state) => state.user,
  initials: (state) => state.user?.email?.slice(0, 2) || "NN",
  hasAuth: (state) => state.user !== null,
};

const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  resetUser(state) {
    state.user = null;
  },
  loadAuth: (state) => {
    if (localStorage.user) {
      state.user = JSON.parse(localStorage.user);
    }
  },
  persist: (state) => {
    localStorage.user = JSON.stringify(state.user);
  },
};

const actions = {
  signout({ commit }, showLoginExpiredToast = false) {
    signOut(auth).then(() => {
      const locale = localStorage.locale;
      sessionStorage.clear();
      localStorage.clear();
      sessionStorage.showLoginExpiredToast = (+showLoginExpiredToast).toString();
      localStorage.locale = locale;

      commit("auth/resetUser");
      commit("auth/persist");
      location.replace(location.href);
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
