<template>
  <div class="flex items-center justify-center min-h-screen bg-white m-auto">
    <div class="text-center">
      <div class="text-primary text-9xl font-bold">404</div>
      <div class="text-black text-6xl font-bold mt-4">
        {{ t("404.ohno") }} <span class="text-primary">{{ t("404.okay") }}</span>
      </div>
      <p class="text-gray-600 mt-4">
        {{ t("404.page_not_found") }}
      </p>
      <router-link
        to="/"
        class="mt-8 inline-block bg-stone-700 text-white py-3 px-6 rounded-md hover:bg-stone-400 transition-colors">
        {{ t("404.back_to_home") }}
      </router-link>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";

export default {
  name: "NotFound",
  setup() {
    const { t } = useI18n();
    return { t };
  },
};
</script>

<style scoped></style>
