import Vuex from "vuex";
import app from "./modules/app";
import auth from "./modules/auth";
import availableKnowledgeAssets from "./modules/availableKnowledgeAssets";
import availableLLMs from "./modules/availableLLMs";
import changelog from "@/store/modules/changelog";
import chat from "@/store/modules/chat";
import chatSearch from "@/store/modules/chatSearch";

export default new Vuex.Store({
  state: {},
  modules: {
    auth,
    chat,
    changelog,
    chatSearch,
    app,
    availableLLMs,
    availableKnowledgeAssets,
  },
  plugins: [],
});
