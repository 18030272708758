<template>
  <div>
    <div
      v-if="!areKnowledgeAssetsLoading && availableKnowledgeAssets(useCase).length > 0"
      class="flex-row space-y-1">
      <div class="flex-row space-y-1 max-h-[23vh] overflow-x-none">
        <div v-for="asset in availableKnowledgeAssets(useCase)" :key="asset.endpointName">
          <div
            @click="toggleSelection(asset)"
            class="flex group pl-4 rounded-md space-x-2 items-center p-2 cursor-pointer hover:bg-slate-300 mx-1">
            <input
              type="checkbox"
              :id="asset.endpointName"
              :value="asset.name"
              :checked="isSelected(asset.name)"
              class="form-checkbox flex cursor-pointer accent-primary" />
            <span
              class="flex-grow text-sm line-clamp-2 break-words leading-normal items-center text-slate-600 cursor-pointer">
              {{ asset.label[selectedLocale] }}
            </span>
            <div class="relative ml-auto flex-shrink-0">
              <FontAwesomeIcon
                icon="gear"
                v-if="useCase === 'personal-asset'"
                @click.stop
                @click.prevent="navigateToKnowledgeSettingsPage(asset.name)"
                class="icon-click-area" />
              <ToolTipButton
                v-else
                :tooltip="asset.description[selectedLocale]"
                size="h-5 w-5"
                class="ml-auto flex-shrink-0 w-5 text-black" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { mapGetters, mapMutations } from "vuex";
import ToolTipButton from "../ToolTipButton.vue";
import config from "@/customer_configs_flags/config";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "KnowledgeAssetSelection",
  components: {
    ToolTipButton,
    FontAwesomeIcon,
  },
  data() {
    return {
      selectedLocale: this.$i18n.locale,
    };
  },
  props: {
    useCase: {
      type: String,
      required: true,
      default: config.useCases[0].name,
    },
  },
  computed: {
    ...mapGetters("availableKnowledgeAssets", [
      "availableKnowledgeAssets",
      "selectedKnowledgeAssets",
      "areKnowledgeAssetsLoading",
    ]),
    selectButtonState() {
      const totalAssets = this.availableKnowledgeAssets(this.useCase).length;
      const selectedCount = this.selectedKnowledgeAssets(this.useCase).length;

      if (selectedCount === 0) {
        return "none-selected";
      } else if (selectedCount === totalAssets) {
        return "all-selected";
      } else {
        return "some-selected";
      }
    },
  },
  methods: {
    ...mapMutations("availableKnowledgeAssets", {
      setSelectedKnowledgeAssets: "setSelectedKnowledgeAssets",
    }),
    isSelected(assetName: string): boolean {
      return this.selectedKnowledgeAssets(this.useCase).some(
        (selectedAsset) => selectedAsset.name === assetName
      );
    },
    selectAll() {
      this.setSelectedKnowledgeAssets({ type: "selectAll", useCase: this.useCase });
    },

    deselectAll() {
      this.setSelectedKnowledgeAssets({ type: "deselectAll", useCase: this.useCase });
    },
    toggleSelection(asset) {
      this.setSelectedKnowledgeAssets({ type: "toggle", asset: asset, useCase: this.useCase });
    },
    navigateToKnowledgeSettingsPage(assetName) {
      this.$router.push({ name: "KnowledgeAssetFinder", params: { assetName: assetName } });
    },
  },
};
</script>
<style scoped>
.icon-click-area {
  padding: 8px;
  margin: -8px;
}
</style>
