import { AllowedModelNames } from "@/store/types/LLMTypes";

const config = {
  // Feature flags
  enableChatSearchSidebar: true,
  enableHelpButton: true,
  enableChatTab: true,
  enableSkillsTab: false,
  enableChangelog: false,
  enableIAPCookies: false,
  // Texts
  defaultLocale: "de",
  helpPageURL:
    "https://docs.google.com/document/d/e/2PACX-1vTOQisO9yxJ1CtwlEbA5gbHj0q7OJbZ5gOahzGGr3XY8EAsR8usgDOpHjzn7fd44rA_4SY72EuuulqM/pub",
  // Use Cases
  useCases: [
    {
      name: "general-asset",
      label: "Shared Collections",
      route: "sales-assistant",
      icon: "fa-magnifying-glass",
    },
    {
      name: "personal-asset",
      label: "My Collections",
      route: "collection",
      icon: "fa-boxes-stacked",
    },
  ],
  // Environment variables
  VUE_APP_ENV: process.env.VUE_APP_ENV,
  VUE_APP_REGION: process.env.VUE_APP_REGION,
  VUE_APP_FIREB_API_KEY: process.env.VUE_APP_FIREB_API_KEY,
  VUE_APP_FIREB_AUTH_DOMAIN: process.env.VUE_APP_FIREB_AUTH_DOMAIN,
  VUE_APP_FIREB_PROJECT_ID: process.env.VUE_APP_FIREB_PROJECT_ID,
  VUE_APP_FIREB_STORAGE_BUCKET: process.env.VUE_APP_FIREB_STORAGE_BUCKET,
  VUE_APP_FIREB_MESSAGING_SENDER_ID: process.env.VUE_APP_FIREB_MESSAGING_SENDER_ID,
  VUE_APP_FIREB_APP_ID: process.env.VUE_APP_FIREB_APP_ID,
  VUE_APP_BACKEND_URL_PATH: process.env.VUE_APP_BACKEND_URL_PATH,
  // Login
  loginProvider: {
    GoogleAuthProvider: true,
    EmailAuthProvider: true,
    OAuthProvider: false,
    OAuthProviderConfig: {},
  },
  // Other Configs
  defaultModelName: AllowedModelNames.GEMINI_1_5_PRO,
  logoExtension: "png",
  inputTokenLimitChat: 100000,
};

export default config;
