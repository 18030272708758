<template>
  <div>
    <router-link
      v-for="({ name, label, icon, secondaryIcon }, key) in filteredLinks"
      :key="key"
      :to="{ name }"
      class="flex w-full mt-1 flex-col text-left rounded-md group"
      :class="{
        ' font-bold bg-slate-200 text-primary': currentRouteName === name,
        ' hover:bg-slate-200 text-slate-700': currentRouteName !== name,
      }">
      <div class="py-2 pl-2 text-sm">
        <div class="flex items-center my-auto capitalize">
          <span class="w-7 pr-2 mx-1 relative">
            <component :is="getIconComponent(icon)" v-bind="getIconProps(icon)" />
            <component
              v-if="secondaryIcon"
              :is="getIconComponent(secondaryIcon)"
              v-bind="getIconProps(secondaryIcon)"
              class="absolute bottom-0 left-0 w-3 h-3 border-2 border-white rounded-full bg-white" />
          </span>
          {{ label }}
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  ChatBubbleOvalLeftIcon,
  MagnifyingGlassIcon,
  LightBulbIcon,
} from "@heroicons/vue/24/outline";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import config from "../customer_configs_flags/config.ts";

export default {
  name: "NavigationComponent",
  components: {
    ChatBubbleIcon: ChatBubbleOvalLeftIcon,
    MagnifyingGlassIcon,
    LightBulbIcon,
    FontAwesomeIcon,
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    currentRouteName() {
      return this.$route.name;
    },
    filteredLinks() {
      const dynamicLinks = config.useCases.map((useCase) => ({
        name: useCase.name,
        label: useCase.label,
        icon: useCase.icon,
        secondaryIcon: useCase.secondaryIcon,
      }));

      const staticLinks = [
        { name: "chat", label: "Chat", icon: "fa-comment", secondaryIcon: null },
        { name: "skills", label: "Skills", icon: "fa-grip", secondaryIcon: null },
      ];

      return [...dynamicLinks, ...staticLinks].filter((link) => {
        if (link.name === "chat" && !config.enableChatTab) {
          return false;
        }
        if (link.name === "skills" && !config.enableSkillsTab) {
          return false;
        }
        return true;
      });
    },
  },
  methods: {
    getIconComponent(icon) {
      if (icon.startsWith("fa-")) {
        return "FontAwesomeIcon";
      }
      switch (icon) {
        case "comment":
          return "ChatBubbleIcon";
        case "glass":
          return "MagnifyingGlassIcon";
        case "lamp":
        default:
          return "LightBulbIcon";
      }
    },
    getIconProps(icon) {
      if (icon.startsWith("fa-")) {
        const iconName = icon.replace("fa-", "");
        return { icon: ["fas", iconName] };
      }
      return {};
    },
  },
};
</script>
