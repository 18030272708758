<template>
  <div class="w-full mt-3 p-2 relative" v-if="hasDocuments">
    <div
      class="uppercase text-xs -mx-4 font-bold mb-1 cursor-pointer text-primary hover:text-primary-dark sticky"
      @click="this.toggleSources()">
      <span class="hover:bg-white rounded-md py-2 px-2 sticky">
        {{ !!this.showSources ? "Hide" : "Show" }} Sources
      </span>
    </div>
    <div
      class="overflow-y-auto -mx-6 transition-all w-full"
      :class="{ 'h-0': !this.showSources, 'h-96 shadow-inner-bottom': showSources }">
      <div
        v-for="(document, key) in conversation.documents"
        :key="key"
        class="px-4 py-2 mt-2 hover:bg-white/40 rounded-md">
        <DocumentCard :deactivate-source-button="true" :document="document" />
      </div>
    </div>
  </div>
</template>
<script>
import { Message } from "@/store/types/ChatTypes";
import { mapMutations } from "vuex";
import DocumentCard from "../DocumentCard.vue";
import config from "../../customer_configs_flags/config.ts";

export default {
  name: "ChatMessageSources",
  components: {
    DocumentCard,
  },
  props: {
    conversation: Message,
  },
  data() {
    return {
      showSources: false,
      featureFlags: config,
    };
  },
  computed: {
    hasDocuments() {
      return this.conversation.documents && this.conversation.documents.length > 0;
    },
  },
  methods: {
    ...mapMutations({
      setDisplayDocument: "app/setDisplayDocument",
      setDisplayPage: "app/setDisplayPage",
    }),
    formatLink(link) {
      const fileName = link.split("/").pop();
      const formatted = fileName.length > 50 ? fileName.slice(0, 50) + "..." : fileName;
      const ending = fileName.split(".").pop();
      return formatted.endsWith("." + ending) ? formatted : formatted + "." + ending;
    },
    toggleSources() {
      this.showSources = !this.showSources;
    },
    getTitle(document) {
      return document.title ?? document.link;
    },
    showPDFDetails(document) {
      this.setDisplayDocument(document);
      this.setDisplayPage(
        document.extractive_answers ? document.extractive_answers[0].pageNumber : 1
      );
    },
  },
};
</script>
