<template>
  <div class="p-2 relative h-full">
    <div class="h-full overflow-auto" ref="pdfContainer">
      <div class="space-y-4 p-4 flex justify-center items-center">
        <div v-if="pages > 0">
          <div
            v-for="page in pages"
            :key="page"
            :id="`page-${page}`"
            class="shadow-md rounded-md mt-2 content-center">
            <VuePDF
              :pdf="pdf"
              :scale="scale"
              :page="page"
              text-layer
              :highlight-text="searchTerm"
              :highlight-options="highlightOptions"
              @click.stop />
          </div>
        </div>
        <div v-else>
          <p class="text-white">{{ t("file_viewer.load_preview") }}</p>
        </div>
      </div>
    </div>

    <div
      class="absolute bottom-4 left-1/2 transform -translate-x-1/2 bg-black bg-opacity-75 rounded-full shadow-md p-2 flex items-center justify-between"
      @click.stop>
      <div class="flex items-center text-white">
        <span class="mx-2"></span>
        <input
          type="number"
          v-model="currentPage"
          @keyup.enter="goToPage"
          class="w-12 py-0.5 bg-opacity-75 bg-slate-900 rounded text-center text-white"
          :min="1"
          :max="pages" />
        <span class="mx-2">/</span>
        <span>{{ pages }}</span>
      </div>
      <div class="flex items-center ml-4">
        <button
          @click="zoomOut"
          :disabled="scale <= 0.5 || pages > 30"
          :class="{ 'opacity-50 cursor-not-allowed': scale <= 0.5 || pages > 30 }"
          class="px-2 py-1 bg-white bg-opacity-20 rounded-full mr-2 text-white hover:bg-opacity-30 transition">
          -
        </button>
        <span class="text-white mx-2">{{ Math.round(scale * 100) }}%</span>
        <button
          @click="zoomIn"
          :disabled="scale >= 2 || pages > 30"
          :class="{ 'opacity-50 cursor-not-allowed': scale >= 2 || pages > 30 }"
          class="px-2 py-1 bg-white bg-opacity-20 rounded-full ml-2 text-white hover:bg-opacity-30 transition">
          +
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, defineProps, onMounted, onUnmounted } from "vue";
import { VuePDF, usePDF } from "@tato30/vue-pdf";
import "@tato30/vue-pdf/style.css";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps({
  document: {
    type: Object,
    required: true,
  },
  refProp: {
    type: String,
    required: false,
  },
  totalPages: {
    type: Number,
    required: false,
  },
  pdf: {
    type: String,
    required: false,
  },
  searchTerm: {
    type: String,
    required: false,
  },
  jumpData: {
    type: Object,
    required: false,
  },
});

const pdfSource = ref(props.pdf || "");
const searchTerm = ref(props.searchTerm || "");
const scale = ref(1);
const currentPage = ref(1);
const pdfContainer = ref(null);

const { pdf, pages } = usePDF(pdfSource);

const highlightOptions = ref({
  completeWords: false,
  ignoreCase: true,
});

watch(
  () => props.searchTerm,
  (newVal) => {
    searchTerm.value = newVal;
  }
);

watch(
  () => props.pdf,
  (newVal) => {
    pdfSource.value = newVal;
  }
);

watch(
  () => props.jumpData,
  (newJumpData) => {
    if (newJumpData) {
      handleJumpToPage(newJumpData);
    }
  },
  { deep: true }
);

const zoomIn = () => {
  if (scale.value < 2 && pages.value <= 30) {
    scale.value += 0.1;
  }
};

const zoomOut = () => {
  if (scale.value > 0.5 && pages.value <= 30) {
    scale.value -= 0.1;
  }
};

const goToPage = () => {
  if (currentPage.value < 1) {
    currentPage.value = 1;
  } else if (currentPage.value > pages.value) {
    currentPage.value = pages.value;
  }
  scrollToPage(currentPage.value);
};

const scrollToPage = (pageNumber) => {
  const pageElement = document.getElementById(`page-${pageNumber}`);
  if (pageElement) {
    pageElement.scrollIntoView({ behavior: "smooth" });
  }
};

const handleJumpToPage = (data) => {
  if (data.metadata && data.metadata.page_span && data.metadata.page_span.page_end) {
    const targetPage = data.metadata.page_span.page_start;
    currentPage.value = targetPage;
    scrollToPage(targetPage);
  }
};

const updateCurrentPage = () => {
  if (!pdfContainer.value) return;

  const containerRect = pdfContainer.value.getBoundingClientRect();
  const pageElements = Array.from(pdfContainer.value.querySelectorAll('[id^="page-"]'));

  const visiblePages = pageElements.filter((el) => {
    const rect = el.getBoundingClientRect();
    return rect.top < containerRect.bottom && rect.bottom > containerRect.top;
  });

  if (visiblePages.length > 0) {
    const mostVisiblePage = visiblePages.reduce((prev, current) => {
      const prevVisible =
        Math.min(containerRect.bottom, prev.getBoundingClientRect().bottom) -
        Math.max(containerRect.top, prev.getBoundingClientRect().top);
      const currentVisible =
        Math.min(containerRect.bottom, current.getBoundingClientRect().bottom) -
        Math.max(containerRect.top, current.getBoundingClientRect().top);
      return currentVisible > prevVisible ? current : prev;
    });

    const newPage = parseInt(mostVisiblePage.id.split("-")[1]);
    if (newPage !== currentPage.value) {
      currentPage.value = newPage;
    }
  }
};

onMounted(() => {
  if (pdfContainer.value) {
    pdfContainer.value.addEventListener("scroll", updateCurrentPage);
    updateCurrentPage();
  }
});

onUnmounted(() => {
  if (pdfContainer.value) {
    pdfContainer.value.removeEventListener("scroll", updateCurrentPage);
  }
});
</script>

<style scoped>
.highlight {
  background-color: red;
}
</style>
