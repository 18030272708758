import "./index.css";
import "floating-vue/dist/style.css";
import "vue-toastification/dist/index.css";
import "./helpers/firebaseAppInit";
import "material-symbols";

import Toast, { POSITION } from "vue-toastification";
import {
  browserLocalPersistence,
  getAuth,
  onAuthStateChanged,
  onIdTokenChanged,
  setPersistence,
} from "firebase/auth";

import App from "./App.vue";
import FloatingVue from "floating-vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Markdown from "vue3-markdown-it";
import VueDragscroll from "vue-dragscroll";
import { createApp } from "vue";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import i18n from "./helpers/i18n_init";
import { library } from "@fortawesome/fontawesome-svg-core";
import router from "./router";
import store from "./store";

library.add(fas, far, fab);

const auth = getAuth();
setPersistence(auth, browserLocalPersistence);

let app;

const initializeApp = () => {
  if (!app) {
    app = createApp(App).component("font-awesome-icon", FontAwesomeIcon);
    app.use(router);
    app.use(store);
    app.use(FloatingVue);
    app.use(Toast, {
      position: POSITION.BOTTOM_RIGHT,
      timeout: 2000,
    });
    app.use(Markdown);
    app.use(VueDragscroll);
    app.use(i18n);
    app.mount("#app");
  }
};

// This will trigger whenever the ID token changes, e.g., when it is refreshed
onIdTokenChanged(auth, async (user) => {
  if (user) {
    await user.getIdToken();
    // Update the user state in your store
    store.commit("auth/setUser", user);
  } else {
    // User is signed out
    store.commit("auth/resetUser");
  }
  initializeApp();
});

// Handle initial app initialization on auth state change
onAuthStateChanged(auth, (user) => {
  if (user) {
    store.commit("auth/setUser", user);
  } else {
    store.commit("auth/resetUser");
  }
  initializeApp();
});

export { auth };
