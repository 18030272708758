export enum AllowedModelNames {
  GPT_3_5 = "gpt-3.5-turbo",
  GPT_4 = "gpt-4-turbo",
  GPT_4_O = "gpt-4o",
  GEMINI_PRO = "gemini-pro",
  GEMINI_1_5_PRO = "gemini-1.5-pro",
  GEMINI_1_5_FLASH = "gemini-1.5-flash",
}

export enum AllowedProviders {
  OPEN_AI = "Open AI",
  GOOGLE_GEMINI = "Google Gemini",
}

export enum AllowedIconTypes {
  ICON = "icon",
  NUMBER = "number",
}

export interface LanguageModel {
  chat_model_name: AllowedModelNames;
  label: string;
  description: string;
  provider: AllowedProviders;
  icon_type: AllowedIconTypes;
  icon: string;
  cost_per_thousand_tokens_in_dollar: number;
  input_token_limit: number;
}

export interface LLMState {
  availableLLMs: LanguageModel[];
  selectedLLMIndex: number;
  areLLMsLoading: boolean;
}
