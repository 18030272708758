<template>
  <div class="bg-white flex justify-between h-screen w-full overflow-hidden">
    <!-- Current chat -->
    <div class="h-full flex justify-center w-full">
      <div class="w-full h-full flex justify-between flex-col items-center">
        <div
          id="chat"
          class="overflow-y-scroll overflow-x-hidden justi-center flex flex-col-reverse w-full h-full items-center"
          :class="{ 'justify-center': chatHistory.length === 0 }">
          <div id="bottom"></div>
          <ChatFollowUpQuestions />
          <ul class="p-10 max-w-7xl flex-row space-y-8 mx-auto w-full">
            <ChatExampleQuestions />
            <li v-for="conversation in chatHistory" :key="conversation.id" class="text-dark">
              <div class="flex-row space-y-6">
                <ChatMessageOut :conversation="conversation" :key="'out' + conversation.id" />
                <ChatMessageIn :conversation="conversation" :key="'in' + conversation.id" />
              </div>
            </li>
          </ul>
        </div>
        <ChatInput />
      </div>
    </div>
    <ChatSidebar
      class="xl:flex"
      :class="{ flex: displayChatSidebar, hidden: !displayChatSidebar }" />
    <div class="flex flex-col items-center justify-center">
      <font-awesome-icon
        :icon="['fas', 'chevron-down']"
        v-if="!displayChatSidebar"
        class="xl:hidden text-3xl rotate-90 cursor-pointer hover:text-slate-500"
        @click="setDisplayChatSidebar(true)" />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import ChatSidebar from "@/components/Chat/ChatSidebar.vue";
import ChatExampleQuestions from "@/components/Chat/ChatExampleQuestions.vue";
import ChatInput from "@/components/Chat/ChatInput.vue";
import ChatMessageOut from "@/components/Chat/ChatMessageOut.vue";
import ChatMessageIn from "@/components/Chat/ChatMessageIn.vue";
import ChatFollowUpQuestions from "@/components/Chat/ChatFollowUpQuestions.vue";

export default {
  name: "ChatComponent",
  components: {
    ChatSidebar,
    ChatExampleQuestions,
    ChatInput,
    ChatMessageIn,
    ChatMessageOut,
    ChatFollowUpQuestions,
  },
  computed: {
    ...mapGetters({
      chatHistory: "chat/chatHistory",
      displayChatSidebar: "app/displayChatSidebar",
    }),
  },
  methods: {
    ...mapMutations({
      setDisplayChatSidebar: "app/setDisplayChatSidebar",
    }),
  },
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.bounce-enter-active {
  animation: bounce-in 0.15s;
}

.bounce-leave-active {
  animation: bounce-in 0.15s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0.975);
  }

  50% {
    transform: scale(1.025);
  }

  100% {
    transform: scale(1);
  }
}

.typewriter span {
  display: inline-block;
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: 0.15em solid transparent; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  animation: typing 1.5s steps(40, end), blink-caret 0.75s 4;
}

.typewriter::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.typewriter {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: lightblue;
  }
}
</style>
