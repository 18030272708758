<template>
  <form
    class="flex flex-col bg-white p-7 rounded-sm"
    @submit.prevent="
      giveFeedback({
        feedback: {
          ...feedback,
          thumbs_choice: currentThumbsChoice,
          entry_id: currentEntryId,
          chat_id: currentChatId,
        },
      })
    ">
    <textarea
      name="individual_feedback"
      :placeholder="placeholder"
      class="w-full bg-light text-dark border border-light rounded-xl py-2 px-4"
      v-model="feedback.individual_feedback">
    </textarea>
    <div class="my-2">
      <div
        v-for="(choiceReason, key) in currentThumbsChoice === 'thumbs_up'
          ? thumbsUpChoicesReasonOptions
          : thumbsDownChoicesReasonOptions"
        :key="key"
        class="my-3">
        <input
          type="checkbox"
          :id="choiceReason"
          :value="choiceReason"
          v-model="feedback.thumbs_choice_reasons" />
        <label :for="choiceReason" class="capitalize ml-1">
          {{ choiceReason }}
        </label>
      </div>
    </div>
    <div class="flex justify-end">
      <button
        type="submit"
        class="btn btn-lg bg-primary text-white px-3 py-2 rounded-sm hover:bg-primary-dark shadow-md hover:shadow-lg">
        Submit feedback
      </button>
    </div>
  </form>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      feedback: {
        thumbs_choice_reasons: [],
        individual_feedback: "",
      },
      thumbsUpChoicesReasonOptions: ["good wording", "valuable content"],
      thumbsDownChoicesReasonOptions: ["no information", "false information", "not understandable"],
    };
  },
  computed: {
    ...mapGetters({
      chatHistory: "chat/chatHistory",
      fullScreenFeedbackId: "chat/fullScreenFeedbackId",
      currentThumbsChoice: "chat/currentThumbsChoice",
      currentEntryId: "chat/currentEntryId",
      currentChat: "chat/currentChat",
      currentChatId: "chat/currentChatId",
    }),
    placeholder() {
      return this.currentThumbsChoice === "thumbs_up"
        ? "What did you like about the answer?"
        : "What did you dislike about the answer?";
    },
  },
  methods: {
    ...mapActions({
      giveFeedback: "chat/giveFeedback",
    }),
  },
};
</script>
