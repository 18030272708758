<template>
  <div
    class="relative inline-flex items-center justify-center w-8 h-8 overflow-hidden rounded-full dark:bg-gray-600"
    :class="this.class"
    :style="{ 'background-color': color }">
    <span class="font-semibold text-cwhite dark:text-light uppercase text-xs">
      {{ initials }}
    </span>
  </div>
</template>
<script>
export default {
  props: {
    initials: {
      type: String,
      required: true,
    },
    class: {
      type: String,
      default: "",
    },
  },
  data() {
    const stringToColour = (str) => {
      let hash = 23490587;
      str.split("").forEach((char) => {
        hash = char.charCodeAt(0) + ((hash << 10) - hash);
      });
      let colour = "#";
      for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xff;
        colour += value.toString(16).padStart(2, "0");
      }
      return colour;
    };
    return {
      color: stringToColour(this.initials),
    };
  },
};
</script>
