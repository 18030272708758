<template>
  <div v-if="documents.length" class="flex flex-col p-4 space-y-4 rounded-xl border-t">
    <div class="flex overflow-x-auto space-x-4">
      <AttachedDocumentPreview
        v-for="document in documents"
        :key="document.title"
        :document="document" />
    </div>
    <div class="h-5 bg-gradient-to-r from-gray-200 to-gray-300 rounded-full overflow-hidden">
      <div
        class="h-full rounded-full bg-gradient-to-r from-green-400 to-green-600 transition-all"
        :class="{ 'bg-red-600 from-red-500 to-red-800': tokenSum > inputTokenLimit }"
        :style="{ width: `${(tokenSum / inputTokenLimit) * 100}%` }"></div>
    </div>
    <div class="flex justify-between items-center text-gray-600">
      <span v-tooltip="t('document_tracker.cost_of_input')" class="text-xs">
        <font-awesome-icon class="text-sm mr-1" icon="fa-solid fa-sack-dollar" />{{
          currentCostOfInputTokens
        }}$
      </span>
      <span v-if="tokenSum > inputTokenLimit" class="text-sm text-red-600 px-2 font-semibold">
        {{ t("document_tracker.token_limit_exceeded") }}
      </span>
      <span class="text-xs ml-auto pr-2"
        >{{ Math.ceil((tokenSum / inputTokenLimit) * 100) }} %</span
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { useI18n } from "vue-i18n";
import config from "@/customer_configs_flags/config";
import AttachedDocumentPreview from "./AttachedDocumentPreview.vue";

export default {
  name: "DocumentTracker",
  setup() {
    const { t } = useI18n();
    return { t };
  },
  components: {
    AttachedDocumentPreview,
  },
  computed: {
    ...mapGetters({
      documents: "chat/currentChatDocuments",
      selectedLLM: "availableLLMs/selectedLLMFullModel",
    }),
    inputTokenLimit() {
      return Math.min(config.inputTokenLimitChat, this.selectedLLM?.input_token_limit ?? Infinity);
    },
    currentCostOfInputTokens() {
      return (
        (this.tokenSum * (this.selectedLLM?.cost_per_thousand_tokens_in_dollar ?? 0.005)) /
        1000
      ).toFixed(4);
    },
    tokenSum() {
      return this.documents.reduce((sum, doc) => sum + (doc.totalTokens || 0), 0);
    },
    isTokenLimitExceeded() {
      return this.tokenSum > this.inputTokenLimit;
    },
  },
  watch: {
    isTokenLimitExceeded(newVal) {
      this.$emit("token-limit-exceeded", newVal);
    },
  },
};
</script>
