<template>
  <div class="relative w-full">
    <textarea
      ref="textarea"
      :id="id"
      :placeholder="placeholder"
      class="w-11/12 outline-none resize-none overflow-y-auto max-h-24"
      :class="[
        {
          'focus:border-b focus:border-slate-300': showFilters,
        },
        backgroundColor,
        tailwindClasses,
      ]"
      v-model="content"
      enterkeyhint="enter"
      rows="1"
      @focus="emitFocus"
      @focusout="showIcon = false"
      @input="resizeTextarea"
      @keydown.shift.enter.exact="insertNewLine"
      @keydown.enter.exact="emitInputConfirmed"></textarea>
    <div
      v-if="showIcon || content.length > 0"
      class="absolute inset-y-0 right-3 flex items-center cursor-pointer text-slate-400 hover:text-secondary-dark"
      @mousedown="clearContent">
      <font-awesome-icon icon="fa-solid fa-xmark" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
    backgroundColor: {
      type: String,
      default: "bg-cwhite",
    },
    tailwindClasses: {
      type: String,
      default: "pt-2 pb-1",
    },
  },
  data() {
    return {
      content: this.modelValue,
      showFilters: false,
      showIcon: {
        type: Boolean,
        default: false,
      },
    };
  },
  watch: {
    content(newVal) {
      this.$emit("update:modelValue", newVal);
    },
    modelValue(newVal) {
      if (newVal !== this.content) {
        this.content = newVal;
      }
    },
  },
  methods: {
    resizeTextarea(event) {
      const textarea = event.target;
      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight + "px";
    },
    insertNewLine(event) {
      event.preventDefault();
      const textarea = event.target;
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      this.content = this.content.substring(0, start) + "\n" + this.content.substring(end);
      this.$nextTick(() => {
        textarea.selectionStart = textarea.selectionEnd = start + 1;
        this.resizeTextarea({ target: textarea });
        textarea.scrollTop = textarea.scrollHeight;
      });
    },
    emitInputConfirmed(event) {
      event.preventDefault();
      this.$emit("inputConfirmed");
    },
    emitFocus(event) {
      this.showIcon = true;
      this.$emit("focus", event);
    },
    emitBlur(event) {
      this.$emit("blur", event);
    },
    clearContent() {
      this.$emit("reset");
      this.content = "";
      this.$nextTick(() => {
        const textarea = this.$refs.textarea;
        textarea.style.height = "auto";
        textarea.style.height = textarea.scrollHeight + "px";
        this.resizeTextarea({ target: textarea });
      });
    },
  },
};
</script>
