import { openDB } from "idb";

const dbPromise = openDB("genow", 5, {
  upgrade(db) {
    if (!db.objectStoreNames.contains("chatSearchChats")) {
      db.createObjectStore("chatSearchChats");
    }
    if (!db.objectStoreNames.contains("chatSearchCurrentChatIds")) {
      db.createObjectStore("chatSearchCurrentChatIds");
    }
  },
});

async function setChatSearchChats(key, value) {
  const db = await dbPromise;
  await db.put("chatSearchChats", JSON.stringify(value), key);
}

async function setChatSearchCurrentChatIds(key, value) {
  const db = await dbPromise;
  await db.put("chatSearchCurrentChatIds", JSON.stringify(value), key);
}

async function getChatSearchCurrentChatIds(key) {
  const db = await dbPromise;
  const serializedValue = await db.get("chatSearchCurrentChatIds", key);
  return serializedValue ? JSON.parse(serializedValue) : null;
}

async function getChatSearchChats(key) {
  const db = await dbPromise;
  const serializedValue = await db.get("chatSearchChats", key);
  return serializedValue ? JSON.parse(serializedValue) : null;
}

async function removeChatSearchChatsFromDB(key) {
  const db = await dbPromise;
  await db.delete("chatSearchChats", key);
}

async function removeChatSearchCurrentChatIdsFromDB(key) {
  const db = await dbPromise;
  await db.delete("chatSearchCurrentChatIds", key);
}

export {
  setChatSearchChats,
  setChatSearchCurrentChatIds,
  getChatSearchCurrentChatIds,
  getChatSearchChats,
  removeChatSearchChatsFromDB,
  removeChatSearchCurrentChatIdsFromDB,
};
