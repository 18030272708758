<template>
  <div :class="classProps">
    <div class="flex justify-start w-full items-center align-middle animate-pulse">
      <div>
        <div class="uppercase text-xs text-gray-400 font-bold pb-1 full flex items-center">
          <!-- Preparing answer -->
          {{ messages[currentMessage] }}
          <LoadingSpinner :show="true" size="w-4 h-4 ml-2" />
        </div>
        <div>
          <div
            v-for="i in [...Array(currentMessage + 1).keys()]"
            :key="'sentence' + i"
            class="text-md text-gray-800 flex mt-3">
            <div
              :key="'word_' + i + '_' + n"
              v-for="n in [...Array(nrWords).keys()]"
              class="animate-pulse bg-gray-200 h-3 rounded-sm mr-3"
              :class="widths[this.getRandomInt(5)]" />
          </div>
        </div>
      </div>
    </div>
    <span v-if="showDisclaimer" class="pt-6 block text-sm text-gray-300">
      Wingman can make mistakes. Exercise caution and verify output diligently.
    </span>
  </div>
</template>
<script>
import LoadingSpinner from "../LoadingSpinner.vue";
export default {
  components: {
    LoadingSpinner,
  },
  props: {
    classProps: {
      type: String,
      default: "py-6 px-4",
    },
    showDisclaimer: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      messages: ["Researching...", "Thinking..."],
      currentMessage: 0,
      timeouts: [],
      nrWords: this.getRandomInt(5) + 3,
      widths: ["w-10", "w-12", "w-14", "w-16", "w-24"],
    };
  },
  methods: {
    increaseMessageCounter() {
      if (this.currentMessage + 1 < this.messages.length) {
        this.currentMessage = this.currentMessage + 1;
      }
    },
    loop() {
      var rand = Math.round(Math.random() * (3000 - 1000)) + 2000;
      this.timeouts.push(
        setTimeout(() => {
          this.increaseMessageCounter();
          this.loop();
        }, rand)
      );
    },
    getRandomInt(max) {
      return Math.floor(Math.random() * max);
    },
  },
  mounted() {
    this.loop();
  },
  unmounted() {
    this.timeouts.forEach((timeout) => {
      clearTimeout(timeout);
    });
  },
};
</script>
