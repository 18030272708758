<template>
  <Transition name="fade">
    <div class="space-y-1">
      <ul>
        <transition-group name="fade">
          <li v-for="(chat, index) in chats" :key="chat.id" class="text-light mt-1">
            <div
              class="flex space-x-2 group rounded-md py-2 pl-2 pr-1 text-sm items-center cursor-pointer hover:bg-slate-300 mx-1"
              :class="chatClasses(chat)"
              @click="setCurrentChat(chat.id)">
              <span
                v-if="documentCounts[index] > 0"
                class="flex flex-row items-center justify-center p-1 border border-gray-200 text-gray-400 group-hover:border-slate-600 group-hover:text-slate-600 h-6 w-6 rounded-lg bg-white">
                <font-awesome-icon
                  :icon="['far', 'file-lines']"
                  class="text-primary text-xs scale-75" />
                <div class="text-xs translate-y-1 scale-75">{{ documentCounts[index] }}</div>
              </span>
              <span
                v-else
                class="text-gray-400 border-gray-200 group-hover:border-slate-600 group-hover:text-slate-600 flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-slate-100">
                {{ lastMessage(chat) ? lastMessage(chat)[0] : "?" }}
              </span>
              <span class="flex-grow text-md line-clamp-1 leading-tight">
                {{ lastMessage(chat) ? lastMessage(chat) : t("chat_list.ask_a_question") + "..." }}
              </span>
              <div class="flex-shrink-0 w-4">
                <div
                  class="cursor-pointer hidden group-hover:block"
                  @click.stop="deleteChat(chat.id)"
                  v-if="chats.length > 1">
                  <TrashIcon class="w-4 h-4" />
                </div>
              </div>
            </div>
          </li>
        </transition-group>
      </ul>
    </div>
  </Transition>
</template>
<script>
import { TrashIcon } from "@heroicons/vue/24/outline";
import { mapMutations, mapGetters, mapActions } from "vuex";
import { useI18n } from "vue-i18n";

export default {
  name: "ChatList",
  setup() {
    const { t } = useI18n();
    return { t };
  },
  components: {
    TrashIcon,
  },
  computed: {
    ...mapGetters({
      currentChat: "chat/currentChat",
      chats: "chat/chats",
      messages: "chat/messages",
      documentCounts: "chat/documentCounts",
    }),
  },
  methods: {
    ...mapActions({
      addChat: "chat/addChat",
    }),
    ...mapMutations({
      setCurrentChat: "chat/setCurrentChat",
      deleteChat: "chat/deleteChat",
      clearSearchData: "search/clearData",
      clearChatData: "chat/clearData",
      clearKpiExtractorData: "kpiExtractor/clearData",
    }),
    lastMessage(chat) {
      if (!this.currentChat) return false;
      return this.messages.find((m) => m.chatId === chat.id)?.request;
    },
    chatClasses(chat) {
      if (!this.currentChat) return "";
      return {
        "text-gray-700": chat.id !== this.currentChat.id,
        "bg-slate-200 text-gray-700 font-bold": chat.id === this.currentChat.id,
      };
    },
    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>
