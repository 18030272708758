<template>
  <div
    class="flex-shrink-0 flex flex-col space-y-1 bg-cwhite hover:bg-slate-100 justify-between py-1 border max-w-40 rounded-lg group">
    <div class="flex items-center space-x-2 pl-2">
      <span class="line-clamp-1 break-words text-slate-700 cursor-pointer" @click="openDocument">{{
        document.title
      }}</span>
      <div class="flex w-5 flex-shrink-0">
        <div
          v-if="document.doneUploading"
          class="hidden group-hover:block cursor-pointer -translate-y-1 px-1"
          @click="removeDocument"
          v-tooltip="t('document_preview.remove_document')">
          <font-awesome-icon icon="fa-solid fa-xmark" class="text-slate-700" />
        </div>
      </div>
    </div>

    <div class="border-t border-slate-100"></div>

    <div class="p-1 cursor-pointer" @click="openDocument">
      <div v-if="!document.doneUploading" class="w-full flex items-center justify-center">
        <div class="border-t-2 border-primary animate-spin h-4 w-4 rounded-full"></div>
      </div>
      <div v-else-if="document.errorMessage" class="flex px-2 items-center">
        <ExclamationTriangleIcon
          class="h-6 w-6 text-red-500 text-lg bg-red-300 rounded-full font-light p-0.5"
          v-tooltip="t(document.errorMessage)" />
      </div>
      <transition v-else name="fade">
        <div class="flex items-center px-2">
          <FileIcon class="mr-3" :title="document.title" iconSize="text-md" />
          <div
            v-tooltip="t('document_preview.token_count')"
            class="ml-auto items-center flex space-x-2">
            <font-awesome-icon icon="fa-solid fa-chart-simple" class="text-slate-700 text-md" />
            <span class="text-xs text-slate-700">{{ document.totalTokens || 0 }}</span>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { ExclamationTriangleIcon } from "@heroicons/vue/24/outline";
import FileIcon from "@/components/FileViewer/FileIcon.vue";

export default {
  name: "AttachedDocumentPreview",
  components: {
    ExclamationTriangleIcon,
    FileIcon,
  },
  setup() {
    const { t } = useI18n();
    return { t };
  },
  props: {
    document: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      currentChatId: "chat/currentChatId",
    }),
  },
  methods: {
    ...mapActions({
      removeDocumentFromChat: "chat/removeDocumentFromChat",
    }),
    ...mapMutations({
      setDisplayDocument: "app/setDisplayDocument",
      setDisplayPage: "app/setDisplayPage",
    }),
    transformDocument(document) {
      // Transform the document into the format expected by DocumentCard
      return {
        title: document.title,
        link: document.link,
        metadata: {
          score: document.relevanceScore ?? 0,
        },
        chunks: [],
      };
    },

    removeDocument() {
      this.removeDocumentFromChat({
        chatId: this.currentChatId,
        document: this.document,
      });
    },

    openDocument() {
      const transformedDocument = this.transformDocument(this.document);
      this.setDisplayDocument(transformedDocument);
      this.setDisplayPage(1);
    },
  },
};
</script>
