import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-bd14e274"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "flex-row space-y-1"
}
const _hoisted_2 = { class: "flex-row space-y-1 max-h-[23vh] overflow-x-none" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["id", "value", "checked"]
const _hoisted_5 = { class: "flex-grow text-sm line-clamp-2 break-words leading-normal items-center text-slate-600 cursor-pointer" }
const _hoisted_6 = { class: "relative ml-auto flex-shrink-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!
  const _component_ToolTipButton = _resolveComponent("ToolTipButton")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.areKnowledgeAssetsLoading && _ctx.availableKnowledgeAssets($props.useCase).length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableKnowledgeAssets($props.useCase), (asset) => {
              return (_openBlock(), _createElementBlock("div", {
                key: asset.endpointName
              }, [
                _createElementVNode("div", {
                  onClick: ($event: any) => ($options.toggleSelection(asset)),
                  class: "flex group pl-4 rounded-md space-x-2 items-center p-2 cursor-pointer hover:bg-slate-300 mx-1"
                }, [
                  _createElementVNode("input", {
                    type: "checkbox",
                    id: asset.endpointName,
                    value: asset.name,
                    checked: $options.isSelected(asset.name),
                    class: "form-checkbox flex cursor-pointer accent-primary"
                  }, null, 8, _hoisted_4),
                  _createElementVNode("span", _hoisted_5, _toDisplayString(asset.label[$data.selectedLocale]), 1),
                  _createElementVNode("div", _hoisted_6, [
                    ($props.useCase === 'personal-asset')
                      ? (_openBlock(), _createBlock(_component_FontAwesomeIcon, {
                          key: 0,
                          icon: "gear",
                          onClick: [
                            _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"])),
                            _withModifiers(($event: any) => ($options.navigateToKnowledgeSettingsPage(asset.name)), ["prevent"])
                          ],
                          class: "icon-click-area"
                        }, null, 8, ["onClick"]))
                      : (_openBlock(), _createBlock(_component_ToolTipButton, {
                          key: 1,
                          tooltip: asset.description[$data.selectedLocale],
                          size: "h-5 w-5",
                          class: "ml-auto flex-shrink-0 w-5 text-black"
                        }, null, 8, ["tooltip"]))
                  ])
                ], 8, _hoisted_3)
              ]))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}