<template>
  <div class="flex-row space-y-2">
    <div class="flex flex-col-reverse space-y-1 max-h-[18vh] overflow-auto">
      <div
        v-for="headingObject in chatHeadingsForUseCase"
        :key="headingObject.chatId"
        class="group flex justify-between rounded-md p-2 text-sm items-center cursor-pointer hover:bg-slate-300 mx-1"
        :class="headingObject.chatId === currentChatIdForUseCase ? ' font-bold' : ' '"
        @click.prevent="setCurrentChatId({ chatId: headingObject.chatId, useCase })">
        <div class="flex gap-x-3 items-center">
          <div
            class="group-hover:border-slate-600 border flex h-6 w-6 shrink-0 items-center justify-center rounded-lg text-[0.625rem]"
            :class="
              headingObject.chatId === currentChatIdForUseCase
                ? 'border-gray-300 border bg-white'
                : 'text-slate-600 border-gray-300'
            ">
            <span>{{ headingObject.chatHeading[0] }}</span>
          </div>
          <span class="text-md line-clamp-1 break-all">
            {{ headingObject.chatHeading }}
          </span>
        </div>
        <div class="flex-shrink-0 w-4">
          <TrashIcon
            v-if="chatHeadingsForUseCase.length > 1"
            class="cursor-pointer hidden group-hover:block"
            @click.stop="removeChatSearchInstance({ chatId: headingObject.chatId, useCase })" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { TrashIcon } from "@heroicons/vue/24/outline";
import { useI18n } from "vue-i18n";
import config from "@/customer_configs_flags/config";

export default {
  name: "ChatSearchHistory",
  setup() {
    const { t } = useI18n();
    return { t };
  },
  props: {
    useCase: {
      type: String,
      required: true,
      default: config.useCases[0].name,
    },
  },
  components: {
    TrashIcon,
  },
  computed: {
    ...mapGetters({
      currentChatId: "chatSearch/currentChatId",
      chatHeadings: "chatSearch/chatHeadings",
    }),
    chatHeadingsForUseCase() {
      return this.chatHeadings(this.useCase);
    },
    currentChatIdForUseCase() {
      return this.currentChatId(this.useCase);
    },
  },
  methods: {
    ...mapActions({
      setCurrentChatId: "chatSearch/setCurrentChatId",
      removeChatSearchInstance: "chatSearch/removeChatSearchInstance",
    }),
  },
};
</script>
