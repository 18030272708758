<template>
  <router-view></router-view>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "App",
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  async mounted() {
    await this.$store.commit("auth/loadAuth");
  },
};
</script>
