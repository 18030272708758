import axios from "axios";
import store from "@/store";

function checkAuth(eventSource) {
  axios.get(eventSource.url).catch((error) => {
    console.error("Error checking EventSource status:", error);
    if (error.response.status === 401 || error.response.status === 403) {
      store.dispatch("auth/signout", true);
    }
  });
}

export default checkAuth;
