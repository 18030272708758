<template>
  <div class="flex h-screen">
    <!-- Left half -->
    <div class="w-3/5 bg-light flex flex-col justify-between items-start p-8">
      <img :src="getLogoPath()" alt="Logo" class="h-12" />
      <div class="flex flex-grow items-center justify-center">
        <h1 class="text-6xl text-primary">{{ displayText }}<span class="cursor">|</span></h1>
      </div>
      <LanguageSwitcher></LanguageSwitcher>
    </div>
    <!-- Right half -->
    <div class="w-2/5 flex flex-col items-center justify-center">
      <h2 class="text-3xl mb-8">
        {{ displayResetPassword ? t("login.password_reset_question") : "Login" }}
      </h2>
      <div v-if="!showEMailDialog" class="flex flex-col justify-center items-center">
        <button
          v-for="(provider, key) in loginProviders"
          class="py-2 w-56 mb-3 rounded-sm px-4"
          :class="cssForProvider(provider)"
          @click="popUpForProvider(provider)"
          :key="key">
          <div class="flex justify-left items-center">
            <font-awesome-icon :icon="iconForProviders[provider]" class="mr-4 transform-none" />
            Sign in with {{ provider }}
          </div>
        </button>
      </div>
      <span v-if="error && !showEMailDialog" class="text-red-600 text-xs w-56">{{
        t("login_error." + error)
      }}</span>
      <form
        @submit.prevent="loginWithMail"
        class="flex flex-col justify-center items-center w-56 space-y-4"
        v-if="showEMailDialog && !displayResetPassword">
        <input
          type="email"
          placeholder="E-Mail"
          v-model="eMail"
          class="bg-c-white border border-gray-300 rounded-sm block w-full p-2.5"
          :class="{
            'focus:ring-primary focus:border-primary text-gray-900': !error,
            'ring-red-600 border-red-600 text-red-600 focus:ring-red-600 focus:border-red-600':
              error,
          }"
          required />
        <input
          type="password"
          :placeholder="t('login.password')"
          v-model="password"
          class="bg-c-white border border-gray-300 sm:text-sm rounded-sm block w-full p-2.5"
          :class="{
            'focus:ring-primary focus:border-primary text-gray-900': !error,
            'ring-red-600 border-red-600 text-red-600 focus:ring-red-600 focus:border-red-600':
              error,
          }"
          required />
        <span v-if="error" class="text-red-600 text-xs">{{ t("login_error." + error) }}</span>
        <div class="flex w-full justify-between gap-x-2">
          <button
            type="button"
            class="text-primary py-1 w-1/2 mb-3 rounded-sm px-2 bg-c-white font-bold hover:bg-stone-50 text-c-white border border-primary"
            @click.stop="hideEMailForm">
            {{ t("login.back") }}
          </button>

          <button
            class="text-white py-2 w-1/2 mb-3 rounded-sm px-4 bg-primary font-bold hover:bg-primary-dark text-c-white"
            type="submit">
            Login
          </button>
        </div>
        <button
          @click.prevent="showResetPassword"
          class="text-secondary py-1 px-4 w-full mb-3 rounded-sm bg-c-white hover:bg-stone-50">
          {{ t("login.forgotPassword") }}
        </button>
      </form>
      <div class="flex flex-col space-y-4">
        <div class="flex w-56 justify-between gap-x-2" v-if="displayResetPassword">
          <input
            type="email"
            placeholder="E-Mail"
            v-model="eMail"
            class="bg-c-white border border-gray-300 rounded-sm block w-full p-2.5"
            :class="{
              'focus:ring-primary focus:border-primary text-gray-900': !error,
              'ring-red-600 border-red-600 text-red-600 focus:ring-red-600 focus:border-red-600':
                error,
            }"
            required />
        </div>
        <div class="flex w-56 justify-between gap-x-2" v-if="displayResetPassword">
          <button
            @click.prevent="hideResetPassword"
            class="text-primary py-1 w-1/2 mb-3 rounded-sm px-2 bg-c-white font-bold hover:bg-stone-50 text-c-white border border-primary">
            {{ t("login.abort") }}
          </button>
          <button
            @click.prevent="resetPassword"
            class="text-white py-2 w-1/2 mb-3 rounded-sm px-4 bg-primary font-bold hover:bg-primary-dark text-c-white">
            {{ t("login.password_reset") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { OAuthProvider } from "firebase/auth";
import featureFlags from "../customer_configs_flags/config.ts";
import { POSITION, useToast } from "vue-toastification";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { signInWithPopup } from "firebase/auth";
import { auth } from "../main.ts";
import { useI18n } from "vue-i18n";
import LanguageSwitcher from "@/components/LanguageSwitcher.vue";

export default {
  name: "LoginComponent",
  components: {
    "font-awesome-icon": FontAwesomeIcon,
    LanguageSwitcher,
  },
  setup() {
    const { t } = useI18n();
    return { t };
  },
  data() {
    return {
      texts: ["Got Questions? Wingman has Answers"],
      customCSSClasses: {
        Microsoft:
          "button-shadow text-white bg-black hover:bg-stone-50 hover:text-slate-400 font-bold",
        Google: "button-shadow font-bold text-slate-400 hover:bg-stone-50 border-slate-300",
        Mail: "button-shadow text-white bg-primary hover:bg-primary-dark font-bold",
      },
      iconForProviders: {
        Microsoft: ["fab", "microsoft"],
        Google: ["fab", "google"],
        Mail: "envelope",
      },
      displayText: "",
      textIndex: 0,
      charIndex: 0,
      showEMailDialog: false,
      eMail: "",
      password: "",
      error: null,
      displayResetPassword: false,
      customOptions: {
        timeout: 5000,
        position: POSITION.TOP_CENTER,
        closeOnClick: true,
        pauseOnFocusLoss: false,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.75,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        icon: true,
        withCredentials: true,
        error: null,
      },
    };
  },
  mounted() {
    this.$forceUpdate();
    this.typeText();
    const toast = useToast();
    if (Number(sessionStorage.getItem("showLoginExpiredToast"))) {
      toast.warning("Login expired!", this.customOptions);
      sessionStorage.removeItem("showLoginExpiredToast");
    }
  },
  computed: {
    textsToDisplay() {
      const { tm } = useI18n();
      const messages = tm("login.messages");
      return messages.length === undefined ? this.texts : messages;
    },
    loginProviders() {
      const providers = [];
      if (featureFlags.loginProvider.GoogleAuthProvider) providers.push("Google");
      if (featureFlags.loginProvider.EmailAuthProvider) providers.push("Mail");
      if (featureFlags.loginProvider.OAuthProvider) providers.push("Microsoft");
      return providers;
    },
  },
  methods: {
    async loginWithMail() {
      try {
        await signInWithEmailAndPassword(auth, this.eMail, this.password);
        this.error = null;
        this.$router.push({ name: "start" });
      } catch (error) {
        this.error = this.getErrorMessage(error.code);
      }
    },
    getLogoPath() {
      return `logo.${featureFlags.logoExtension}`;
    },
    cssForProvider(provider) {
      return this.customCSSClasses[provider];
    },
    async popUpForProvider(provider) {
      let authProvider;
      switch (provider) {
        case "Google":
          authProvider = new GoogleAuthProvider();
          break;
        case "Microsoft":
          authProvider = new OAuthProvider(
            featureFlags.loginProvider.OAuthProviderConfig.providerNameKey
          );
          authProvider.setCustomParameters(
            featureFlags.loginProvider.OAuthProviderConfig.customParameters
          );
          break;
        case "Mail":
          this.showEMailDialog = true;
          return;
        default:
          console.error("Unknown provider: " + provider);
          return;
      }

      try {
        await signInWithPopup(auth, authProvider);
        this.$router.push({ name: "start" });
      } catch (error) {
        console.error(error.code);
        this.error = this.getErrorMessage(error.code);
      }
    },
    showResetPassword() {
      this.displayResetPassword = true;
    },
    hideResetPassword() {
      this.displayResetPassword = false;
    },
    hideEMailForm() {
      this.showEMailDialog = false;
    },
    typeText() {
      if (this.charIndex < this.textsToDisplay[this.textIndex].length) {
        this.displayText += this.textsToDisplay[this.textIndex].charAt(this.charIndex);
        this.charIndex++;
        setTimeout(this.typeText, 100);
      } else {
        setTimeout(this.resetText, 2000);
      }
    },
    resetText() {
      this.displayText = "";
      this.charIndex = 0;
      this.textIndex = (this.textIndex + 1) % this.textsToDisplay.length;
      this.typeText();
    },
    async resetPassword() {
      try {
        await sendPasswordResetEmail(auth, this.eMail);
        this.error = null;
        useToast().success("Password reset email sent!", this.customOptions);
      } catch (error) {
        this.error = this.getErrorMessage(error.code);
      }
    },
    getErrorMessage(errorCode) {
      var knownErrorCodes = [
        "auth/user-disabled",
        "auth/wrong-password",
        "auth/cancelled-popup-request",
        "auth/popup-closed-by-user",
        "auth/claims-too-large",
        "auth/email-already-exists",
        "auth/id-token-expired",
        "auth/id-token-revoked",
        "auth/insufficient-permission",
        "auth/internal-error",
        "auth/invalid-argument",
        "auth/invalid-claims",
        "auth/invalid-continue-uri",
        "auth/invalid-creation-time",
        "auth/invalid-credential",
        "auth/invalid-disabled-field",
        "auth/invalid-display-name",
        "auth/invalid-dynamic-link-domain",
        "auth/invalid-email",
        "auth/invalid-email-verified",
        "auth/invalid-hash-algorithm",
        "auth/invalid-hash-block-size",
        "auth/invalid-hash-derived-key-length",
        "auth/invalid-hash-key",
        "auth/invalid-hash-memory-cost",
        "auth/invalid-hash-parallelization",
        "auth/invalid-hash-rounds",
        "auth/invalid-hash-salt-separator",
        "auth/invalid-id-token",
        "auth/invalid-last-sign-in-time",
        "auth/invalid-page-token",
        "auth/invalid-password",
        "auth/invalid-password-hash",
        "auth/invalid-password-salt",
        "auth/invalid-phone-number",
        "auth/invalid-photo-url",
        "auth/invalid-provider-data",
        "auth/invalid-provider-id",
        "auth/invalid-oauth-responsetype",
        "auth/invalid-session-cookie-duration",
        "auth/invalid-uid",
        "auth/invalid-user-import",
        "auth/maximum-user-count-exceeded",
        "auth/missing-android-pkg-name",
        "auth/missing-continue-uri",
        "auth/missing-hash-algorithm",
        "auth/missing-ios-bundle-id",
        "auth/missing-uid",
        "auth/missing-oauth-client-secret",
        "auth/operation-not-allowed",
        "auth/phone-number-already-exists",
        "auth/project-not-found",
        "auth/reserved-claims",
        "auth/session-cookie-expired",
        "auth/session-cookie-revoked",
        "auth/too-many-requests",
        "auth/uid-already-exists",
        "auth/unauthorized-continue-uri",
        "auth/user-not-found",
      ];

      if (knownErrorCodes.includes(errorCode)) {
        return errorCode;
      }
      return "unknownError";
    },
  },
};
</script>

<style scoped>
.cursor {
  display: inline-block;
  width: 10px;
  margin-left: 2px;
  animation: blink 1s infinite;
}
@keyframes blink {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}

.button-shadow {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
</style>
