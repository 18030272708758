<template>
  <div
    class="file-list-overlay bg-white p-4 rounded-lg shadow-lg w-[40vw] min-w-[500px] h-screen fixed right-0 top-0 z-30 overflow-y-auto">
    <div class="flex justify-between items-center mb-4">
      <h2 class="text-xl font-semibold">
        {{ getReferencesFromConversation.length }} {{ t("chat-search.references") }}
      </h2>
    </div>
    <ul>
      <li v-for="(document, index) in getReferencesFromConversation" :key="index" class="mb-2 p-3">
        <!-- Render document details -->
        <DocumentCard :document="document" :deactivateViewButton="true" />
      </li>
    </ul>
  </div>
</template>

<script>
import DocumentCard from "../DocumentCard.vue";
import { useI18n } from "vue-i18n";
import config from "../../customer_configs_flags/config.ts";

export default {
  name: "FileList",
  setup() {
    const { t } = useI18n();
    return { t };
  },
  components: {
    DocumentCard,
  },
  props: {
    documents: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      featureFlags: config,
    };
  },
  computed: {
    getReferencesFromConversation() {
      const conversation = this.documents;
      if (!conversation) return [];

      const mergedReferences = {};

      conversation.forEach((item) => {
        if (item.references) {
          item.references.forEach((reference) => {
            const id = reference.metadata.source_id;

            if (!mergedReferences[id]) {
              // If the reference with this id is not already added, add it
              mergedReferences[id] = {
                ...reference,
                chunks: [
                  ...new Set(
                    reference.chunks.map(
                      (chunk) => (typeof chunk === "string" ? chunk.trim() : chunk) // Ensure chunk is a string before trimming
                    )
                  ),
                ], // Ensure chunks are unique
              };
            } else {
              // If the reference with this id is already added, merge the chunks
              const existingChunks = new Set(
                mergedReferences[id].chunks.map((chunk) =>
                  typeof chunk.metadata.id === "string"
                    ? chunk.metadata.id.trim()
                    : chunk.metadata.id
                )
              );
              reference.chunks.forEach((chunk) => {
                const trimmedChunkID =
                  typeof chunk.metadata.id === "string"
                    ? chunk.metadata.id.trim()
                    : chunk.metadata.id;
                if (!existingChunks.has(trimmedChunkID)) {
                  mergedReferences[id].chunks.push(chunk);
                  existingChunks.add(chunk);
                }
              });
            }
          });
        }
      });

      // Convert the mergedReferences object back to an array
      return Object.values(mergedReferences);
    },
  },
};
</script>
