<template>
  <div class="h-screen">
    <!-- Sidebar -->
    <loading-overlay v-if="displayLoadingOverlay">Loading...</loading-overlay>
    <!-- Overlays for PDFs and search/chat option -->
    <transition name="fade" mode="out-in">
      <font-awesome-icon
        class="absolute overflow-hidden z-40 top-7 right-7 text-xl cursor-pointer text-primary hover:text-secondary"
        icon="fa-solid fa-x"
        @click="closeAllOverlays"
        v-if="showDocument || showFullScreenFeedback || displayAsset || showFileList" />
    </transition>
    <transition name="fade" mode="out-in">
      <div
        class="h-screen w-full flex items-center overflow-y-auto z-30 absolute top-0"
        v-if="showDocument">
        <div
          class="h-screen w-full overflow-y-auto z-30 absolute top-0 bg-gray-800/90 p-10"
          @click="closeAllOverlays"></div>
        <div class="mx-auto z-30 w-5/6">
          <FileViewer v-if="showDocument" @close-overlay="closeAllOverlays" />
        </div>
      </div>
    </transition>

    <!-- File List Overlay -->
    <transition name="fade" mode="out-in">
      <div
        class="h-screen w-full flex items-center overflow-y-auto z-30 absolute top-0"
        v-if="showFileList">
        <div
          class="h-screen w-full overflow-y-auto z-30 absolute top-0 bg-gray-800/90 p-10"
          @click="closeAllOverlays"></div>
        <div class="mx-auto z-30 w-5/6">
          <FileList :documents="showFileList" @close="closeAllOverlays" />
        </div>
      </div>
    </transition>

    <!-- Other overlays -->
    <transition name="fade" mode="out-in">
      <div
        class="h-screen w-full overflow-y-auto z-30 absolute top-0 bg-gray-800/80 p-10"
        v-if="showFullScreenFeedback">
        <div class="w-1/2 mx-auto">
          <fullscreen-feedback :id="fullScreenFeedbackId"></fullscreen-feedback>
        </div>
      </div>
    </transition>

    <transition name="fade" mode="out-in">
      <div
        class="h-screen w-full overflow-y-auto z-30 absolute top-0 bg-gray-800/80 p-10"
        v-if="displayChangelog">
        <div v-if="featureFlags.enableChangelog" class="w-1/2 mx-auto">
          <changelog></changelog>
        </div>
      </div>
    </transition>

    <transition name="fade" mode="out-in">
      <div
        class="h-screen w-full overflow-y-auto z-30 absolute top-0 bg-gray-800/80 p-10"
        @click="
          () => {
            setDisplayAsset(false);
          }
        "
        v-if="displayAsset">
        <div class="w-1/2 mx-auto">
          <asset-viewer></asset-viewer>
        </div>
      </div>
    </transition>

    <div class="flex h-screen w-screen overflow-hidden bg-white">
      <Sidebar class="xl:flex" :class="{ flex: displaySidebar, hidden: !displaySidebar }" />
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import FileViewer from "../components/FileViewer/FileViewer.vue";
import FileList from "../components/FileViewer/FileList.vue"; // Importiere die FileList-Komponente
import AssetViewer from "../components/AssetViewer.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import FullsreenFeedback from "../components/FullscreenFeedback.vue";
import LoadingOverlay from "../components/LoadingOverlay.vue";
import Sidebar from "../components/Sidebar.vue";
import AllowPopUpComponent from "../components/AllowPopUpComponent.vue";
import ChangelogComponent from "../components/ChangelogComponent";
import { useToast } from "vue-toastification";
import config from "../customer_configs_flags/config.ts";

export default {
  components: {
    FileViewer,
    FileList,
    "asset-viewer": AssetViewer,
    Sidebar,
    "font-awesome-icon": FontAwesomeIcon,
    "fullscreen-feedback": FullsreenFeedback,
    "loading-overlay": LoadingOverlay,
    changelog: ChangelogComponent,
  },
  name: "StartComponent",
  data() {
    return {
      featureFlags: config,
    };
  },
  computed: {
    ...mapGetters({
      showDocument: "app/showDocument",
      showFileList: "app/showFileList",
      showFullScreenFeedback: "chat/showFullScreenFeedback",
      fullScreenFeedbackId: "chat/fullScreenFeedbackId",
      displayLoadingOverlay: "app/displayLoadingOverlay",
      displayAsset: "app/displayAsset",
      availableLLMs: "availableLLMs/availableLLMs",
      user: "auth/user",
      changelogHasBeenDisplayed: "changelog/changelogHasBeenDisplayed",
      displayChangelog: "app/displayChangelog",
      changelog: "changelog/changelog",
      latestTag: "changelog/latestTag",
      displaySidebar: "app/displaySidebar",
    }),
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    ...mapMutations({
      setDisplayDocument: "app/setDisplayDocument",
      setDisplayFileList: "app/setDisplayFileList",
      hideFullScreenFeedback: "chat/hideFullScreenFeedback",
      showLoadingOverlay: "app/showLoadingOverlay",
      hideLoadingOverlay: "app/hideLoadingOverlay",
      showChangelog: "app/showChangelog",
      loadChangelogCache: "changelog/loadCache",
      setUser: "auth/setUser",
      setChangelogHasBeenDisplayed: "changelog/setChangeLogHasBeenDisplayed",
      persistChangelog: "changelog/persist",
      setDisplaySidebar: "app/setDisplaySidebar",
      setDisplayAsset: "app/setDisplayAsset",
    }),
    ...mapActions({
      fetchAvailableKnowledgeAssets: "availableKnowledgeAssets/fetchAvailableKnowledgeAssets",
      fetchAvailableLLMs: "availableLLMs/fetchAvailableLLMs",
      fetchChangelog: "changelog/fetch",
      initializeChatSearchState: "chatSearch/initializeChatSearchState",
    }),
    checkPopUpAllowed() {
      const testWindow = window.open("", "_blank");
      if (testWindow) {
        testWindow.close();
        return true;
      } else {
        return false;
      }
    },
    showPopUpAllowDialog() {
      const toast = useToast();
      this.allowPopUpComponentID = toast.warning(
        {
          component: AllowPopUpComponent,
          listeners: {
            doneClicked: () => {
              this.initializeApp();
              toast.dismiss(this.allowPopUpComponentID);
            },
          },
        },
        {
          position: "top-right",
          timeout: 10000,
          closeOnClick: false,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: false,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: false,
          rtl: false,
        }
      );
    },
    closeAllOverlays() {
      this.setDisplayDocument(null);
      this.hideFullScreenFeedback();
      this.setDisplayAsset(false);
      this.setDisplayFileList(null);
    },
    async initializeApp() {
      await this.fetchAvailableKnowledgeAssets();
      await this.fetchAvailableLLMs();
      await this.initializeChatSearchState();
      if (this.featureFlags.enableChangelog) {
        await this.loadChangelogCache();
        await this.fetchChangelog();
        if (this.verifyVersion()) {
          if (!this.changelogHasBeenDisplayed) {
            this.showChangelog();
          }
        } else {
          this.setChangelogHasBeenDisplayed(false);
          this.persistChangelog();
        }
      }
    },
    verifyVersion() {
      const versionExtractionRegex = /v?(\d+\.\d+(\.\d+)?)/;
      const versionMatch = this.latestTag.match(versionExtractionRegex);

      if (versionMatch) {
        const version = versionMatch[1];
        const versionRegex = new RegExp(
          `## ${version.replace(/\./g, "\\.")}([\\s\\S]*?)(?=^## \\d|^$)`,
          "gm"
        );
        const match = versionRegex.exec(this.changelog);

        if (match) {
          return true;
        } else {
          console.error(`Version ${version} not found in changelog. Make sure your changelog entry is formatted as follows:
## ${version}
- Your change description here
- Another change description`);
          return false;
        }
      } else {
        console.error(`Invalid version format: ${this.latestTag}. Ensure your version tag follows one of these formats:
- v0.1.0
- 0.1.0
- v0.1
- 0.1`);
        return false;
      }
    },
  },
  mounted() {
    this.initializeApp();
  },
};
</script>
<style>
.ellipsis {
  text-overflow: ellipsis;
  /* enables ellipsis */
  white-space: nowrap;
  /* keeps the text in a single line */
  overflow: hidden;
  /* keeps the element from overflowing its parent */
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.bounce-enter-active {
  animation: bounce-in 0.15s;
}

.bounce-leave-active {
  animation: bounce-in 0.15s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0.975);
  }

  50% {
    transform: scale(1.025);
  }

  100% {
    transform: scale(1);
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>
