<template>
  <div
    v-if="show"
    class="absolute z-50 flex w-fit justify-center items-center overflow-visible"
    :class="positionClasses"
    ref="fileInputWrapper">
    <input
      type="file"
      ref="fileInput"
      :multiple="acceptMultipleFiles"
      class="absolute inset-0 opacity-0 cursor-pointer"
      @change="handleFileUpload" />
    <div
      class="relative cursor-pointer border-dashed border-2 border-slate-500 hover:border-slate-700 bg-cwhite hover:bg-slate-200 rounded-lg h-28 p-4 flex items-center justify-center text-gray-600"
      @dragover.prevent
      @drop.prevent="handleDrop"
      @click="triggerFileInput">
      <font-awesome-icon
        icon="fa-solid fa-file-import"
        class="text-2xl text-primary hover:text-primary-dark" />
      <span class="ml-2 overflow-auto max-h-24">{{ t("file_input.upload_document_prompt") }}</span>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useI18n } from "vue-i18n";

export default {
  name: "FileInput",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    acceptMultipleFiles: {
      type: Boolean,
      default: false,
    },
    positionClasses: {
      type: String,
      default: "",
    },
  },
  watch: {
    show(newVal) {
      if (newVal) {
        // Add a short delay before attaching the event listener
        setTimeout(() => {
          document.addEventListener("click", this.handleClickOutside, true);
        }, 0);
      } else {
        document.removeEventListener("click", this.handleClickOutside, true);
      }
    },
  },
  setup() {
    const { t } = useI18n();
    return { t };
  },
  components: {
    "font-awesome-icon": FontAwesomeIcon,
  },
  methods: {
    handleFileUpload(event) {
      const files = Array.from(event.target.files);
      this.$emit("file-upload", files);
    },
    handleDrop(event) {
      const files = Array.from(event.dataTransfer.files);
      this.$emit("file-upload", files);
    },
    handleClose() {
      this.$emit("close");
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleClickOutside(event) {
      if (this.$refs.fileInputWrapper && !this.$refs.fileInputWrapper.contains(event.target)) {
        this.handleClose();
      }
    },
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside, true);
  },
};
</script>
