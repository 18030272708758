<template>
  <div class="w-full bg-white shadow-inner">
    <div class="grid grid-cols-3 gap-6 py-20 px-32">
      <div
        class="flex flex-col justify-between w-full rounded-sm shadow-md bg-white text-dark hover:shadow-xl p-6">
        <div>
          <h1 class="font-bold text-xl">{{ t("skills.skills") }}</h1>
          <h2 class="mt-4">
            {{ t("skills.skills_description") }}
          </h2>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useI18n } from "vue-i18n";

export default {
  name: "AutomationComponent",
  setup() {
    const { t } = useI18n();
    return { t };
  },
};
</script>
