import config from "@/customer_configs_flags/config";
import { initializeApp } from "firebase/app";

const app = initializeApp({
  apiKey: config.VUE_APP_FIREB_API_KEY,
  authDomain: config.VUE_APP_FIREB_AUTH_DOMAIN,
  projectId: config.VUE_APP_FIREB_PROJECT_ID,
  storageBucket: config.VUE_APP_FIREB_STORAGE_BUCKET,
  messagingSenderId: config.VUE_APP_FIREB_MESSAGING_SENDER_ID,
  appId: config.VUE_APP_FIREB_APP_ID,
});

export default app;
