<template>
  <div class="flex w-full items-center px-12" v-if="chatHistory.length === 0">
    <div class="flex items-center w-full">
      <div
        class="relative block w-full rounded-lg border-2 border-dashed border-gray-300 text-center">
        <div class="pt-12 pb-6 px-12">
          <font-awesome-icon class="w-6 h-6 text-gray-400" icon="fa-full fa-comment" />
          <span class="mt-2 block text-sm font-semibold text-gray-400">{{
            t("chat_example_questions.try_asking")
          }}</span>
        </div>
        <ul class="divide-y divide-gray-200 rounded-md mt-8 text-sm text-left px-12 pb-12 mb-10">
          <li
            @dblclick="askQuestion({ question: example, locale: $i18n.locale })"
            @click="setNextQuestion(example)"
            :key="example"
            class="py-4 px-5 border-b-1 hover:rounded-md hover:bg-light cursor-pointer"
            v-for="(example, index) in examplesForMode(selectedMode)">
            <span
              class="w-full block uppercase text-xs font-bold full text-gray-400 group-hover:text-gray-600">
              {{ t("shared.example") }} {{ index + 1 }}
            </span>
            <span class="block text-gray-600 group-hover:text-gray-800">
              {{ example }}
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { ChatMode } from "@/store/types/ChatTypes";
import config from "@/customer_configs_flags/config";
import { useI18n } from "vue-i18n";

export default {
  name: "ChatComponent",
  setup() {
    const { t } = useI18n();
    return { t };
  },
  methods: {
    ...mapActions({
      askQuestion: "chat/askQuestion",
    }),
    ...mapMutations({
      setNextQuestion: "chat/setNextQuestion",
    }),
    examplesForMode(mode) {
      const { tm } = useI18n();
      if (this.currentChatDocuments.length > 1) {
        return tm(this.exampleQuestionsObject["multiple_documents"]);
      }
      return tm(this.exampleQuestionsObject[mode]);
    },
  },
  computed: {
    ...mapGetters({
      chatHistory: "chat/chatHistory",
      selectedMode: "chat/mode",
      currentChatDocuments: "chat/currentChatDocuments",
    }),
  },
  components: {
    "font-awesome-icon": FontAwesomeIcon,
  },

  data() {
    return {
      featureFlags: config,
      exampleQuestionsObject: {
        [ChatMode.Conversation]: "chat_example_questions.conversation",
        [ChatMode.Document]: "chat_example_questions.document",
        multiple_documents: "chat_example_questions.multiple_documents",
      },
    };
  },
};
</script>
