<template>
  <div
    class="mini-document-card relative bg-slate-100 p-2 cursor-pointer rounded-md hover:bg-slate-300"
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave">
    <div class="flex items-center text-sm text-slate-500" @click="handleOpenDocument">
      <FileIcon :title="link" :class="'text-sm'" />
      <div class="font-medium text-xs font-mono">
        <span class="block">{{ truncatedDocumentTitle }}</span>
        <span class="block text-gray-400">{{ fileName }}</span>
      </div>
    </div>
    <transition name="fade" @before-enter="beforeEnter" @after-leave="afterLeave">
      <div
        v-if="showFullCard"
        ref="fullCard"
        class="absolute bg-white shadow-lg rounded-md p-4 mt-1 border-gray-200 border z-30 w-96"
        :style="popperStyle"
        @mouseenter="handleMouseEnter"
        @mouseleave="handleMouseLeave">
        <DocumentCard :document="document" :numPreviewChunks="0" />
      </div>
    </transition>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";
import FileIcon from "./FileViewer/FileIcon.vue";
import DocumentCard from "./DocumentCard.vue";
import config from "../customer_configs_flags/config.ts";

export default {
  name: "MiniDocumentCard",
  components: {
    FileIcon,
    DocumentCard,
  },
  props: {
    document: Object,
  },
  data() {
    return {
      showFullCard: false,
      hoverTimeout: null,
      popperInstance: null,
      popperStyle: {},
      featureFlags: config,
    };
  },
  computed: {
    truncatedDocumentTitle() {
      const documentTitle = this.document.title;
      return documentTitle?.length > 20 ? documentTitle.slice(0, 20) + "..." : documentTitle;
    },
    fileName() {
      return this.document.fileName?.length > 20
        ? this.document.fileName.slice(0, 20) + "..." + this.fileType
        : this.document.fileName;
    },
    link() {
      return this.document.link ?? this.document.path;
    },
    fileType() {
      return this.link?.split(".").pop().toString();
    },
  },
  methods: {
    handleMouseEnter() {
      if (this.hoverTimeout) {
        clearTimeout(this.hoverTimeout);
        this.hoverTimeout = null;
      }
      this.showFullCard = true;
      this.$nextTick(() => {
        if (this.$refs.fullCard && !this.popperInstance) {
          this.popperInstance = createPopper(this.$el, this.$refs.fullCard, {
            placement: "bottom-start",
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, 8],
                },
              },
            ],
          });
          this.popperStyle = {
            visibility: "visible",
          };
        }
      });
    },
    handleMouseLeave() {
      this.hoverTimeout = setTimeout(() => {
        this.showFullCard = false;
      }, 200);
    },
    beforeEnter() {
      this.popperStyle = {
        visibility: "hidden",
      };
    },
    afterLeave() {
      if (this.popperInstance) {
        this.popperInstance.destroy();
        this.popperInstance = null;
      }
    },
    handleOpenDocument() {
      this.showFullCard = false;
      this.$emit("miniCardClick", this.document);
    },
  },
};
</script>

<style scoped>
.mini-document-card {
  transition: bg-slate-400 0.3s ease;
}
.fade-enter-active {
  transition: opacity 0.2s;
}
.fade-leave-active {
  transition: opacity 0.1s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
