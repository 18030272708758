<template>
  <div
    class="text-white rounded-xl flex items-center justify-center bg-gray-500 w-20 h-8 font-bold text-xs shadow-md hover:shadow-lg ml-3">
    <font-awesome-icon class="text-white w-3 h-3 mr-1" icon="fa-solid fa-globe"></font-awesome-icon>
    <select class="bg-gray-500" v-model="selectedLocale" @change="changeLocale">
      <option v-for="locale in availableLocales" :key="locale" :value="locale">{{ locale }}</option>
    </select>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
export default {
  cmomponents: {
    FontAwesomeIcon,
  },
  data() {
    return {
      selectedLocale: this.$i18n.locale,
      availableLocales: this.$i18n.availableLocales,
    };
  },
  methods: {
    changeLocale() {
      this.$i18n.locale = this.selectedLocale;
      localStorage.locale = this.selectedLocale;
    },
  },
};
</script>
