<template>
  <div class="flex flex-col justify-between h-full">
    <div class="flex justify-between flex-wrap">
      <div class="flex pt-1.5 items-center justify-between">
        <FileIcon :title="link" />
        <div>
          <span
            ref="titleSpan"
            class="block text-md text-gray-800 truncate"
            v-tooltip="disableDocumentTitleTooltip ? undefined : { content: documentTitle }">
            {{ documentTitle }}
          </span>
          <span class="block text-sm text-gray-400">
            {{ fileName }}
          </span>
        </div>
        <font-awesome-icon
          v-if="document.metadata && document.metadata.score"
          :icon="priorityIcon"
          class="px-1.5 text-gray-700"
          v-tooltip="scoreTooltip" />
      </div>
      <div
        class="flex flex-wrap ml-7 text-xs items-start justify-end pt-2 hidden"
        v-if="document.metadata && document.metadata.source_metadata">
        <div
          class="px-3 mr-2 py-1 rounded-xl border text-primary line-clamp-1"
          v-for="[key, value] of Object.entries(document.metadata.source_metadata)"
          v-tooltip="getAttributeTitle(key)"
          :key="key">
          {{ value }}
        </div>
      </div>
    </div>
    <div v-if="chunks">
      <Markdown class="message text-md mb-2" v-if="answer" :source="answer" />
      <div
        v-for="(chunk, key) in chunks"
        :key="key"
        class="px-4 py-4 mt-2 rounded-xl bg-cwhite"
        :class="{
          hidden: !showChunks && key >= numPreviewChunks,
          'hover:bg-slate-100 cursor-pointer': enableJumpEvent,
        }"
        @click="enableJumpEvent && emitJumpEvent(chunk)">
        <div class="flex justify-between mb-2 text-md">
          <span class="font-bold text-xs text-gray-400">
            {{ t("document_card.snippet") + " " + (key + 1) }}
            {{
              chunk.metadata?.page_span?.page_start && chunk.metadata?.page_span?.page_start !== 1
                ? "- " + t("document_card.page") + " " + chunk.metadata.page_span.page_start
                : ""
            }}
            {{
              chunk.metadata?.score
                ? "- " +
                  t("document_card.relevance") +
                  " " +
                  (chunk.metadata?.score * 100).toFixed(0) +
                  "%"
                : ""
            }}
          </span>
          <div class="flex items-center space-x-2">
            <button
              @click.prevent="openDocument(document, chunk.metadata?.page_span?.page_start ?? 1)"
              v-if="!deactivateViewButton && chunk.metadata?.page_span?.page_start != 1"
              class="flex items-center text-primary text-xs hover:bg-slate-100 px-2 py-1 rounded-md">
              <font-awesome-icon :icon="['fas', 'eye']" class="mr-2" />
              <span class="text-slate-500 font-medium">{{ t("document_card.view") }}</span>
            </button>
            <font-awesome-icon
              :icon="['fas', 'chevron-down']"
              class="text-slate-500 cursor-pointer hover:bg-slate-100 hover:rounded-md p-0.5"
              :class="{ 'rotate-180': activeChunk === key }"
              @click="toggleChunk(key)" />
          </div>
        </div>
        <Markdown
          :class="{
            'line-clamp-custom': activeChunk !== key,
            message: activeChunk === key,
          }"
          :source="chunk.page_content"
          v-if="!isJSON" />
        <div
          :class="{
            'line-clamp-custom': activeChunk !== key,
            message: activeChunk === key,
          }"
          v-html="convertJSONToHTMLList(chunk.page_content)"
          v-else />
      </div>
    </div>

    <div
      class="flex mt-2"
      :class="{ 'justify-end': chunks.length === 0, 'justify-between': chunks.length > 0 }">
      <button
        id="toggleChunksButton"
        class="flex justify-center"
        v-if="chunks.length > 0 && chunks.length > numPreviewChunks">
        <div
          @click="toggleChunksVisibility"
          class="text-primary hover:text-primary-dark text-md cursor-pointer hover:bg-slate-100 hover:shadow-md px-3 py-2 rounded-md">
          <font-awesome-icon :icon="['far', 'file-lines']" class="cursor-pointer text-primary" />
          <span class="text-xs relative top-1"> {{ chunks.length }}</span>
          <font-awesome-icon
            :icon="['fas', 'chevron-down']"
            class="cursor-pointer text-slate-500"
            :class="{ 'rotate-180': showChunks }" />
        </div>
      </button>
      <div id="view/clipboard/download-buttons" class="flex justify-center-y">
        <button
          @click.prevent="
            openDocument(document, document.chunks[0].metadata?.page_span?.page_start)
          "
          v-if="!deactivateViewButton"
          class="flex items-center text-primary text-xs cursor-pointer hover:bg-slate-100 hover:shadow-md px-3 py-2 rounded-md">
          <font-awesome-icon :icon="['fas', 'eye']" class="mr-2" />
          <span class="text-slate-500 font-medium">{{ t("document_card.view") }}</span>
        </button>
        <button
          v-if="!deactivateDownloadButton"
          class="flex items-center text-primary text-xs cursor-pointer hover:bg-slate-100 hover:shadow-md px-3 py-2 rounded-md"
          @click.prevent="download({ location: document.location, link: document.link })">
          <font-awesome-icon :icon="['fas', 'download']" class="mr-2" />
          <span class="text-slate-500 font-medium">Download</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import FileIcon from "./FileViewer/FileIcon.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { mapGetters } from "vuex";
import { mapMutations } from "vuex";
import { useI18n } from "vue-i18n";
import Markdown from "vue3-markdown-it";

export default {
  name: "DocumentCard",
  setup() {
    const { t } = useI18n();
    return { t };
  },
  components: {
    "font-awesome-icon": FontAwesomeIcon,
    FileIcon,
    Markdown,
  },
  props: {
    document: Object,
    deactivateViewButton: {
      type: Boolean,
      default: false,
    },
    enableJumpEvent: {
      type: Boolean,
      default: false,
    },
    deactivateDownloadButton: {
      type: Boolean,
      default: false,
    },
    numPreviewChunks: {
      type: Number,
      default: 5,
    },
  },
  data() {
    return {
      disableDocumentTitleTooltip: false,
      activeChunk: null,
      showChunks: false,
    };
  },
  methods: {
    toggleChunk(key) {
      if (this.activeChunk === key) {
        this.activeChunk = null; // Collapse the current chunk if clicked again
      } else {
        this.activeChunk = key; // Expand the clicked chunk and collapse others
      }
    },
    ...mapMutations({
      setDisplayPage: "app/setDisplayPage",
      setDisplayDocument: "app/setDisplayDocument",
    }),
    emitJumpEvent(chunk) {
      this.$emit("jumpToPage", chunk);
    },
    convertJSONToHTMLList(json) {
      // Check if the input is a valid JSON string and parse it
      if (typeof json === "string") {
        try {
          json = JSON.parse(json);
        } catch (e) {
          console.error("Invalid JSON string provided");
          return "<p> Invalid JSON string provided </p>";
        }
      }

      // Recursive function to process JSON and create HTML lists
      function createList(data) {
        let html = "<ul>";

        if (Array.isArray(data)) {
          data.forEach((item) => {
            html += "<li>" + createList(item) + "</li>";
          });
        } else if (typeof data === "object" && data !== null) {
          Object.keys(data).forEach((key) => {
            html += `<li>${key}: ` + createList(data[key]) + "</li>";
          });
        } else {
          html += data;
        }

        html += "</ul>";
        return html;
      }

      // Generate the HTML list from the JSON data
      return createList(json);
    },
    getFileExtension(fileName) {
      // Check if the file name contains a dot and is not the first character
      const dotIndex = fileName.lastIndexOf(".");
      if (dotIndex > 0 && dotIndex < fileName.length - 1) {
        // Return the substring from the last dot to the end of the string
        return fileName.substring(dotIndex + 1);
      } else {
        // Return an empty string if no valid extension is found
        return "";
      }
    },
    getAttributeTitle(key) {
      return key.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase());
    },
    toggleChunksVisibility() {
      this.showChunks = !this.showChunks;
    },
    switchShowSource(chunk) {
      chunk.showSource = !chunk.showSource;
    },
    openDocument(document, page = 1) {
      this.setDisplayDocument(document);
      this.setDisplayPage(page);
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    priorityIcon() {
      let priorityLevel;
      const score = this.document.metadata.score;

      if (score >= 0.9) {
        priorityLevel = "Critical";
      } else if (score >= 0.7) {
        priorityLevel = "Major";
      } else if (score >= 0.6) {
        priorityLevel = "Highest";
      } else if (score >= 0.5) {
        priorityLevel = "High";
      } else if (score >= 0.4) {
        priorityLevel = "Medium";
      } else if (score >= 0.3) {
        priorityLevel = "Low";
      } else if (score >= 0.2) {
        priorityLevel = "Lowest";
      } else if (score >= 0.1) {
        priorityLevel = "Minor";
      } else {
        priorityLevel = "Trivial";
      }

      const iconMap = {
        Critical: "fa-solid fa-arrow-up-long",
        Major: "fa-solid fa-angles-up",
        Highest: "fa-solid fa-chevron-up",
        High: "fa-solid fa-arrow-up",
        Medium: "fa-solid fa-equals",
        Low: "fa-solid fa-arrow-down",
        Lowest: "fa-solid fa-chevron-down",
        Minor: "fa-solid fa-angles-down",
        Trivial: "fa-solid fa-circle",
      };

      return iconMap[priorityLevel] || "fa-solid fa-question-circle";
    },
    scoreTooltip() {
      const score = (this.document.metadata.score * 100).toFixed(0);

      return `${this.t("document_card.relevance")} ${score}%`;
    },
    isJSON() {
      return this.getFileExtension(this.document.metadata.source_title).includes("json");
    },

    answer() {
      return this.document.extractive_answers
        ? this.document.extractive_answers
            .map((answer) => `${answer.content} (Page ${answer.pageNumber})`)
            .join("<br/>")
        : "";
    },
    documentTitle() {
      const documentTitle = this.document.title;

      return documentTitle?.length > 80 ? documentTitle.slice(0, 40) + "..." : documentTitle;
    },
    fileName() {
      return this.document.fileName?.length > 60
        ? this.document.fileName.slice(0, 60) + "..." + this.fileType
        : this.document.fileName;
    },
    link() {
      return this.document.link ?? this.document.path;
    },
    fileType() {
      return this.link?.split(".").pop().toString();
    },
    chunks() {
      return this.document.chunks;
    },
  },
  mounted() {
    this.$nextTick(() => {
      const element = this.$refs.titleSpan;

      if (element) {
        const isTruncated = element.scrollWidth > element.clientWidth;
        this.disableDocumentTitleTooltip = !isTruncated;
      }
    });
  },
};
</script>
<style>
h1,
h2 {
  font-weight: bolder;
}

h3,
h4,
h5,
h6 {
  font-weight: bold;
}
.line-clamp-custom {
  max-height: 3rem; /* Die Höhe anpassen, um etwa 2 Zeilen Text anzuzeigen */
  overflow: hidden;
  position: relative;
}
.line-clamp-custom::after {
  content: "";
  position: absolute;
  overflow: hidden;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1.5rem; /* Passt diese Höhe entsprechend an */
}
</style>
