<template>
  <font-awesome-icon
    icon="fa-solid fa-file-pdf"
    class="text-red-400 pr-2"
    :class="iconSize"
    v-if="icon === 'pdf'" />
  <font-awesome-icon
    icon="fa-solid fa-file"
    class="text-blue-400 pr-2"
    :class="iconSize"
    v-else-if="icon === 'doc' || icon === 'docx' || icon === 'odt'" />
  <font-awesome-icon
    icon="fa-solid fa-file-csv"
    class="text-green-400 pr-2"
    :class="iconSize"
    v-else-if="icon === 'csv'" />
  <font-awesome-icon
    icon="fa-solid fa-file-excel"
    class="text-green-400 pr-2"
    :class="iconSize"
    v-else-if="icon === 'xls' || icon === 'xlsx' || icon === 'ods'" />
  <font-awesome-icon
    icon="fa-solid fa-file"
    class="text-orange-400 pr-2"
    :class="iconSize"
    v-else-if="icon === 'ppt' || icon === 'pptx' || icon === 'odp'" />
  <font-awesome-icon
    icon="fa-solid fa-table-cells"
    class="text-primary pr-2"
    :class="iconSize"
    v-else-if="icon === 'json' || icon === 'ndjson'" />
  <font-awesome-icon
    icon="fa-solid fa-file-code"
    class="text-primary pr-2"
    :class="iconSize"
    v-else-if="icon === 'html'" />
  <font-awesome-icon v-else icon="fa-solid fa-file" class="text-primary pr-2" :class="iconSize" />
</template>
<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  components: {
    "font-awesome-icon": FontAwesomeIcon,
  },
  props: {
    title: String,
    iconSize: {
      type: String,
      default: "text-2xl",
    },
  },
  computed: {
    icon() {
      return this.title?.split(".").pop().toLowerCase();
    },
  },
};
</script>
