<template>
  <div
    class="items-center transition-all rounded-3xl"
    :class="{
      'block min-h-32 w-full': localState === 'generate-button-clicked',
      'flex genow-gradient-bg focus:genow-gradient-bg w-36':
        localState !== 'generate-button-clicked',
      'border border-blue-400 bg-cwhite': localState === 'generate-button-clicked' && !isFailure,
      'border border-red-200 bg-red-50 text-red-600':
        localState === 'generate-button-clicked' && isFailure,
    }">
    <div
      class="w-full items-centers h-full"
      :class="{
        'p-6': localState === 'generate-button-clicked',
        'px-4 py-2 flex items-center': localState !== 'generate-button-clicked',
      }">
      <div v-if="localState === 'generate-button-clicked'">
        <div
          class="flex items-center mb-2"
          :class="{ 'text-red-600': isFailure, 'text-slate-600': !isFailure }">
          <SparklesIcon class="w-5 h-5 mr-2"></SparklesIcon>
          {{ answer.length == 0 ? t("generate_button.generate_using_gemini") : task }}
          <button
            @click="$emit('resetState')"
            class="hover:text-gray-700 focus:outline-none ml-auto"
            :class="{ 'text-gray-500': !isFailure, 'text-red-600': isFailure }">
            <font-awesome-icon icon="fa-solid fa-times" class="text-lg" />
          </button>
        </div>

        <Markdown
          ref="markdownContent"
          class="w-full markdown"
          :class="{ 'text-slate-700': !isFailure, 'text-red-600': isFailure }"
          v-if="answer.length && localState === 'generate-button-clicked'"
          :source="answer + (loading ? ' ⬤' : '')" />

        <LoadingSkeleton v-if="loading && !answer.length"></LoadingSkeleton>
        <div v-if="answer.length === 0 && !loading">
          <textarea
            class="bg-transparent outline-none w-full min-h-32 text-slate-500 rounded-md p-2 bg-blue-50"
            style="max-height: 22rem"
            :value="lastTask ? lastTask : task"
            @input="task = $event.target.value"
            @keydown.enter="handleEnter"
            autofocus />
          <div class="flex justify-between items-center mt-2">
            <h2 class="text-primary mb-2" :class="{ invisible: !showExampleTasks }">
              {{ t("generate_button.example_tasks") }}:
            </h2>
            <font-awesome-icon
              :icon="showExampleTasks ? ['fas', 'chevron-up'] : ['fas', 'chevron-down']"
              @click="toggleShowExampleTasks"
              class="text-primary hover:cursor-pointer" />
          </div>
          <div v-if="showExampleTasks === true">
            <ul class="list-disc pl-5">
              <li
                v-for="exampleTask in tm('generate_button.example_sentences')"
                :key="exampleTask"
                @click="selectExampleTask(exampleTask)"
                @dblclick="selectExampleTaskAndGenerate(exampleTask)"
                class="cursor-pointer text-slate-500 text-sm hover:underline hover:text-base hover:text-slate-700">
                {{ exampleTask }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <button
        v-if="localState !== 'generate-button-clicked'"
        class="bg-transparent outline-none flex-grow text-left text-white mr-1"
        @click="$emit('generateButtonClicked')">
        <font-awesome-icon
          :icon="['fas', 'bolt']"
          class="text-white text-lg mr-3"
          v-if="localState !== 'generate-button-clicked'" />
        {{ t("generate_button.generate") }}
      </button>

      <div class="flex justify-end mt-3" v-if="localState === 'generate-button-clicked'">
        <button
          class="rounded-xl bg-cwhite hover:bg-slate-200 border px-4 py-2 mr-2"
          :class="{
            'border-blue-800  text-secondary-dark': !isFailure,
            'border-red-200  text-red-600': isFailure,
          }"
          v-if="answer.length"
          @click="refine()">
          Refine
        </button>
        <button
          class="rounded-xl bg-cwhite hover:bg-slate-200 border px-4 py-2 mr-2"
          :class="{
            'border-blue-800  text-secondary-dark': !isFailure,
            'border-red-200  text-red-600': isFailure,
          }"
          v-if="answer.length"
          @click="reset()">
          Reset
        </button>
        <button
          class="rounded-xl text-cwhite px-4 py-2 bg-secondary hover:bg-secondary-dark"
          v-if="answer.length === 0"
          @click="generate()">
          {{ t("generate_button.generate") }}
        </button>
        <button
          v-if="answer.length > 0 && !isFailure"
          class="rounded-xl text-cwhite bg-secondary hover:bg-secondary-dark px-4 py-2"
          @click="copy()">
          Copy
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { SparklesIcon } from "@heroicons/vue/24/outline";
import authenticatedEventSource from "@/helpers/authenticatedEventSource";
import config from "@/customer_configs_flags/config";
import debounce from "@/helpers/debounce";
import getEndpoint from "@/helpers/endpoints";
import { mapGetters } from "vuex";
import Markdown from "vue3-markdown-it";
import LoadingSkeleton from "./LoadingSkeleton.vue";
import { useI18n } from "vue-i18n";
import pause from "@/helpers/pause";

export default {
  setup() {
    const { t, tm } = useI18n();
    return { t, tm };
  },
  props: {
    state: {
      type: String,
      default: "nothing-clicked",
    },
    knowledgeAssetName: {
      type: String,
      default: "",
    },
    useCase: {
      type: String,
      default: config.useCases[0].name,
    },
  },
  components: {
    SparklesIcon,
    Markdown,
    LoadingSkeleton,
  },
  data() {
    return {
      localState: this.state,
      task: "",
      answer: "",
      loading: false,
      showExampleTasks: true,
      lastTask: "",
      isFailure: false,
      lock: null,
    };
  },
  watch: {
    state(newVal) {
      this.localState = newVal;
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      llm: "availableLLMs/selectedLLM",
      currentConversationStore: "chatSearch/currentConversation",
    }),
    currentConversation() {
      return this.currentConversationStore(this.useCase);
    },
  },
  methods: {
    setReply(answer, error) {
      this.answer = answer;
      this.isFailure = error;
    },
    setFinalResult(data) {
      this.answer = data;
    },
    async addToMessage(data) {
      if (!this.lock) {
        this.lock = Promise.resolve();
      }

      this.lock = this.lock.then(async () => {
        for (let i = 0; i < data.length; i++) {
          this.answer += data[i];
          await pause(5);
        }
      });

      await this.lock;
    },
    reset() {
      this.answer = "";
      this.task = "";
      this.lastTask = "";
      this.isFailure = false;
    },
    refine() {
      this.task = this.lastTask;
      this.isFailure = false;
      this.answer = "";
    },
    toggleShowExampleTasks() {
      this.showExampleTasks = !this.showExampleTasks;
    },
    generate: debounce(async function () {
      const { t } = this;
      this.lastTask = this.task;
      const maxCharacterLimit = 2500;

      if (this.task.length > maxCharacterLimit) {
        this.$store.dispatch("app/warn", {
          message: `Character limit exceeded. Max allowed is ${maxCharacterLimit} characters.`,
        });
        return;
      }

      this.loading = true;
      try {
        const params = new URLSearchParams();
        const currentConversation = this.currentConversation[this.knowledgeAssetName];

        params.append(
          "data",
          JSON.stringify({
            query: this.task,
            chat_id: currentConversation.id,
            llm: this.llm,
            locale: this.$i18n.locale,
          })
        );
        const eventSource = await authenticatedEventSource.create(
          getEndpoint(`/api/search/generation?${params.toString()}`)
        );

        eventSource.onmessage = (event) => {
          const parse = JSON.parse(event.data);
          const type = parse.type;
          const data = parse.data;
          switch (type) {
            case "ANSWER":
              this.addToMessage(data);
              break;
            case "ERROR":
              this.setReply({
                reply: t("chat.error_message"),
                error: true,
              });
              eventSource.close();
              break;
            case "FINISHED":
              eventSource.close();
              this.loading = false;
              break;
            default:
              console.error("Unknown event type: ", type);
              break;
          }
        };

        eventSource.onerror = () => {
          this.setReply({
            reply: t("chat.error_message"),
            error: true,
          });
          eventSource.close();
        };
      } catch (error) {
        console.error("Error generating content:", error);
        this.answer = t("generate_button.error");
        this.isFailure = true;
      }
    }),
    copy() {
      const markdownContent = this.$refs.markdownContent.$el;
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = markdownContent.innerHTML;
      document.body.appendChild(tempDiv);

      const range = document.createRange();
      range.selectNode(tempDiv);
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);

      try {
        document.execCommand("copy");
      } catch (err) {
        console.error("Could not copy content: ", err);
      }

      selection.removeAllRanges();
      document.body.removeChild(tempDiv);
    },
    selectExampleTask(exampleTask) {
      this.task = exampleTask;
    },
    selectExampleTaskAndGenerate(exampleTask) {
      this.selectExampleTask(exampleTask);
      this.generate();
    },
    handleEnter(event) {
      if (!event.shiftKey) {
        this.generate();
        event.preventDefault();
      }
    },
  },
};
</script>
<style>
.markdown li {
  list-style-type: circle;
}
</style>
