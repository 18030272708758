function debounce(func, wait: number = 250) {
  // debounce function to prevent multiple requests
  let timeout;
  return function (...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}

export default debounce;
