<template>
  <div class="pt-1 px-20 max-w-7xl pb-5 w-full relative">
    <DocumentTracker @token-limit-exceeded="handleInputTokenLimitExceeded" />
    <form
      class="w-full px-1 overflow-visible"
      @submit.prevent="
        () => {
          if (!currentlyAnswering && !inputTokenLimitExceeded) {
            sendRequest({ locale: this.$i18n.locale });
          }
        }
      ">
      <div
        class="w-full flex mx-auto overflow-visible items-center space-x-3 px-2 rounded-full border bg-cwhite relative">
        <button
          id="uploadDocumentButton"
          type="button"
          v-tooltip.top="t('chat_input.upload_document')"
          class="flex items-center box-content p-3 w-4 h-4 rounded-full cursor-pointer hover:bg-slate-200 text-slate-900"
          @click="toggleUploadField">
          <font-awesome-icon icon="fa-solid fa-paperclip" class="text-xl -translate-x-0.5" />
        </button>
        <div class="relative w-full">
          <TextAreaWithResize
            tailwindClasses="leading-relaxed pt-3 pb-1"
            id="questionInput"
            :placeholder="t('chat_input.ask_a_question') + '...'"
            v-model="nextQuestion"
            @inputConfirmed="sendRequest()" />
          <FileInput
            :show="showUploadField"
            :acceptMultipleFiles="true"
            @file-upload="handleFileUpload"
            @close="toggleUploadField"
            positionClasses="bottom-12 -left-14 shadow-sm" />
        </div>
        <LoadingSpinner
          class="btn cursor-pointer btn-lg box-content p-3 w-4 h-4 m-2 transition"
          size="w-4 h-4"
          :show="currentlyAnswering"
          v-if="this.llm && currentlyAnswering" />

        <div
          @click="
            () => {
              if (currentlyAnswering) {
                stopGeneration();
              } else {
                sendRequest();
              }
            }
          "
          v-tooltip.top="currentlyAnswering ? t('chat_input.stop_generation') : ''"
          class="btn cursor-pointer btn-lg text-cwhite box-content p-3 w-4 h-4 rounded-full transition text-sm"
          :class="sendButtonClasses"
          :disabled="currentlyAnswering || !this.llm || inputTokenLimitExceeded">
          <LoadingSpinner size="w-4 h-4" :show="currentlyAnswering" v-if="!this.llm" />
          <font-awesome-icon
            class="w-4 h-4"
            v-else-if="currentlyAnswering && this.llm"
            icon="fa-solid fa-stop" />
          <font-awesome-icon class="w-4 h-4" icon="fa-solid fa-arrow-up" v-else />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import TextAreaWithResize from "@/components/TextAreaWithResize.vue";
import DocumentTracker from "@/components/Chat/DocumentTracker.vue";
import FileInput from "@/components/FileInput.vue";
import { useI18n } from "vue-i18n";

export default {
  name: "ChatInput",
  setup() {
    const { t } = useI18n();
    return { t };
  },
  components: {
    DocumentTracker,
    "font-awesome-icon": FontAwesomeIcon,
    LoadingSpinner,
    TextAreaWithResize,
    FileInput,
  },
  data() {
    return {
      showUploadField: false,
      lastToggleTime: 0,
      inputTokenLimitExceeded: false,
    };
  },
  computed: {
    ...mapGetters({
      nextQuestion: "chat/nextQuestion",
      currentlyAnswering: "chat/currentlyAnswering",
      selectedMode: "chat/mode",
      llm: "availableLLMs/selectedLLM",
    }),
    nextQuestion: {
      get() {
        return this.$store.getters["chat/nextQuestion"];
      },
      set(value) {
        this.$store.commit("chat/setNextQuestion", value);
      },
    },
    sendButtonClasses() {
      return {
        "bg-primary hover:bg-primary-dark":
          !this.currentlyAnswering && !this.inputTokenLimitExceeded && this.llm,
        "bg-gray-400 hover:bg-gray-500 cursor-wait": this.currentlyAnswering,
        "bg-gray-400 hover:bg-gray-500 cursor-no-drop": this.inputTokenLimitExceeded || !this.llm,
      };
    },
  },
  methods: {
    ...mapMutations({
      setNextQuestion: "chat/setNextQuestion",
    }),
    ...mapActions({
      askQuestion: "chat/askQuestion",
      stopGeneration: "chat/stopGeneration",
      uploadDocument: "chat/uploadDocument",
    }),
    sendRequest() {
      const maxCharacterLimit = 2500;

      if (this.nextQuestion.length > maxCharacterLimit) {
        this.$store.dispatch("app/warn", {
          message: `Character limit exceeded. Max allowed is ${maxCharacterLimit} characters.`,
        });
        return;
      }

      if (!this.currentlyAnswering && !this.inputTokenLimitExceeded) {
        this.askQuestion({ locale: this.$i18n.locale });
        this.nextQuestion = "";
      } else {
        this.$store.dispatch("app/warn", { message: "Token limit exceeded" });
      }
    },
    toggleUploadField() {
      const now = Date.now();
      if (now - this.lastToggleTime < 50) {
        return;
      } // Prevent toggling too quickly
      this.lastToggleTime = now;
      this.showUploadField = !this.showUploadField;
    },
    handleInputTokenLimitExceeded(value) {
      this.inputTokenLimitExceeded = value;
    },
    handleFileUpload(files) {
      files.forEach((file) => this.uploadDocument({ file }));
      this.showUploadField = false;
    },
  },
};
</script>

<style scoped></style>
