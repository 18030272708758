<template>
  <div class="items-center space-y-3">
    <div class="flex items-center mb-1 text-xs font-semibold text-gray-400">
      <span> LLM </span>
      <div v-if="llmsLoading">
        <LoadingSpinner :show="true" size="pl-2 w-3 h-3" />
      </div>
    </div>
    <div v-for="(models, provider) in groupedLLMs" :key="provider">
      <div
        @click="toggleProvider(provider)"
        :class="[
          'flex flex-row cursor-pointer text-xs font-semibold ',
          isProviderVisible(provider)
            ? 'text-gray-400'
            : providerOfSelectedLLM === provider
            ? 'text-secondary font-bold'
            : 'text-gray-400',
        ]">
        <span> {{ provider }} </span>
        <ChevronDoubleDownIcon
          v-tooltip="
            isProviderVisible(provider) ? t('llm_selection.collapse') : t('llm_selection.collapse')
          "
          class="h-4 w-4 ml-auto mr-1"
          :class="
            isProviderVisible(provider)
              ? ' rotate-180 text-gray-400'
              : providerOfSelectedLLM === provider
              ? 'text-secondary font-bold'
              : 'text-gray-400'
          " />
      </div>
      <div v-if="isProviderVisible(provider)" class="space-y-1 mt-2">
        <div
          v-for="(option, key) in models"
          :key="key"
          :class="selectClassBasedOnModelSelection(option, llm)"
          @click.prevent="
            () => {
              setLLM(option);
            }
          ">
          <div
            class="group-hover:border-slate-600 border-2 flex h-6 w-6 shrink-0 items-center justify-center rounded-lg text-[0.625rem] font-medium bg-white"
            :class="
              llm === option.chat_model_name
                ? 'border-primary border-2 text-secondary font-bold'
                : 'text-gray-400 border-gray-200 font-semibold'
            ">
            <span v-if="option.icon_type === 'number'">
              {{ option.icon }}
            </span>
            <BoltIcon class="p-1" v-else-if="isBoltIcon(option)" />
            <SparklesIcon class="p-1" v-else-if="isSparklesIcon(option)" />
            <span v-else>{{ option.icon }}</span>
          </div>
          <span class="text-md">
            {{ option.label }}
            <span
              v-if="isDefaultModel(option)"
              class="border border-zinc-200 bg-zinc-100 text-xs shadow-sm rounded-full px-2 py-0.5"
              >default</span
            >
          </span>
          <ToolTipButton
            :tooltip="option.description[$i18n.locale]"
            size="h-5 w-5"
            class="ml-auto w-5 flex-shrink-0" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import { SparklesIcon, BoltIcon, ChevronDoubleDownIcon } from "@heroicons/vue/24/outline";
import LoadingSpinner from "./LoadingSpinner.vue";
import ToolTipButton from "@/components/ToolTipButton.vue";
import { ChatMode } from "../store/types/ChatTypes.ts";
import { useI18n } from "vue-i18n";
import config from "@/customer_configs_flags/config";

export default {
  name: "LLMSelection",
  setup() {
    const { t } = useI18n();
    return { t };
  },
  components: {
    SparklesIcon,
    BoltIcon,
    ChevronDoubleDownIcon,
    LoadingSpinner,
    ToolTipButton,
  },
  computed: {
    ...mapGetters("availableLLMs", ["areLLMsLoading", "availableLLMs", "selectedLLM"]),
    llm() {
      return this.selectedLLM;
    },
    llmsLoading() {
      return this.areLLMsLoading;
    },
    defaultLLM() {
      return config.defaultModelName;
    },
    groupedLLMs() {
      return this.availableLLMs.reduce((modelsByProvider, llm) => {
        if (!modelsByProvider[llm.provider]) {
          modelsByProvider[llm.provider] = [];
        }
        modelsByProvider[llm.provider].push(llm);
        return modelsByProvider;
      }, {});
    },
    providerOfSelectedLLM() {
      const selectedLLM = this.llm;
      return this.availableLLMs.find((llm) => llm.chat_model_name === selectedLLM)?.provider;
    },
  },
  data() {
    return {
      ChatMode,
      visibleProviders: {},
    };
  },
  watch: {
    availableLLMs: {
      handler: "initializeVisibleProviders",
      immediate: true,
    },
  },
  methods: {
    ...mapMutations({
      setStoreLLM: "availableLLMs/setSelectedLLMIndex",
    }),
    setLLM(llm) {
      this.setStoreLLM(llm);
    },
    selectClassBasedOnModelSelection(option, llm) {
      return [
        option.chat_model_name === llm
          ? "text-secondary bg-white font-bold"
          : "text-gray-700 font-semibold",
        "hover:text-secondary hover:bg-white group flex gap-x-3 rounded-md py-2 pl-6 pr-2 text-sm font-semibold items-center cursor-pointer",
      ];
    },
    isBoltIcon(option) {
      return option.icon_type === "icon" && option.icon === "bolt";
    },
    isSparklesIcon(option) {
      return option.icon_type === "icon" && option.icon === "sparkles";
    },
    toggleProvider(provider) {
      this.visibleProviders[provider] = !this.visibleProviders[provider];
    },
    isProviderVisible(provider) {
      return this.visibleProviders[provider];
    },
    isDefaultModel(option) {
      return option.chat_model_name === this.defaultLLM;
    },
    initializeVisibleProviders() {
      this.availableLLMs.forEach((model) => {
        if (!this.visibleProviders[model.provider]) {
          this.visibleProviders[model.provider] = false;
        }
      });
      const selectedProvider = this.providerOfSelectedLLM ?? false;
      if (selectedProvider) {
        this.visibleProviders[selectedProvider] = true;
      }
    },
  },
};
</script>
