<template>
  <div v-if="show" :class="size" class="inline-block relative">
    <div
      :class="size"
      class="box-border block absolute border-2 border-t-blue-900 rounded-full animate-spin duration-1200 cubic-bezier(0.5, 0, 0.5, 1)"></div>
    <!-- <div :class="size" class="box-border block absolute border-2 border-t-blue-900 rounded-full animate-spin duration-1200 cubic-bezier(0.5, 0, 0.5, 1) del delay-250"></div>
        <div :class="size" class="box-border block absolute border-2 border-t-blue-900 rounded-full animate-spin duration-1200 cubic-bezier(0.5, 0, 0.5, 1) delay-100"></div>
        <div :class="size" class="box-border block absolute border-2 border-t-blue-900 rounded-full animate-spin duration-100 cubic-bezier(0.5, 0, 0.5, 1) delay-150"></div> -->
  </div>
</template>
<script>
export default {
  name: "LoadingSpinner",

  props: {
    show: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: "w-6 h-6",
    },
  },
};
</script>
