<template>
  <div class="flex-row space-y-2">
    <div class="flex justify-end">
      <div class="message max-w-[600px] w-[65%] px-8 py-6 rounded-lg bg-slate-200">
        <span>{{ conversation.request }}</span>
      </div>
    </div>
    <div class="flex items-center justify-end">
      <span class="uppercase text-xs font-bold">{{ t("shared.you") }}</span>
      <div class="w-8 h-8 rounded-full ml-3">
        <initial-avatar :initials="initials"> </initial-avatar>
      </div>
    </div>
  </div>
</template>
<script>
// import components and export default
import InitialAvatar from "@/components/InitialAvatar.vue";
import { mapGetters } from "vuex";
import { Message } from "@/store/types/ChatTypes";
import { useI18n } from "vue-i18n";

export default {
  name: "ChatMessageOut",
  setup() {
    const { t } = useI18n();
    return { t };
  },
  components: {
    InitialAvatar,
  },
  props: {
    conversation: Message,
  },
  computed: {
    ...mapGetters({
      initials: "auth/initials",
      user: "auth/user",
    }),
  },
};
</script>
