<template>
  <div class="fixed z-50 bg-black/80 flex items-center justify-center h-full w-full">
    <div
      class="w-1/3 h-1/3 bg-white rounded-md shadow-lg flex items-center align-center justify-center">
      <loading-spinner />
      <span class="text-sm font-bold pl-3 block"><slot /></span>
    </div>
  </div>
</template>
<script>
import LoadingSpinner from "./LoadingSpinner.vue";
export default {
  components: {
    LoadingSpinner,
  },
};
</script>
<style>
.v-clip {
  border-width: 10px !important;
}
</style>
