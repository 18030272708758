<template>
  <div class="w-full flex justify-between items-center text-center">
    <font-awesome-icon :icon="['fas', 'circle-arrow-up']" class="text-2xl" />
    <div class="flex justify-center text-center items-center gap-x-2">
      <span class="material-symbols-outlined"> iframe_off </span>
      <a
        href="https://docs.google.com/document/d/e/2PACX-1vTPzFFxUgVieeCb-R_LqPrBx-oXHYRVUe2REeIBvCsmueyxhe4uEkyVRt_31ROzuZTik3gIA4VAusbU/pub"
        target="_blank"
        class="hover:underline underline-offset-2"
        >Enable Pop-Ups</a
      >
    </div>
    <button
      @click="doneButtonClicked()"
      class="text-sm rounded-sm py-1 px-2 border border-white hover:text-dark hover:bg-white">
      Done
    </button>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  components: {
    "font-awesome-icon": FontAwesomeIcon,
  },
  name: "AllowPopUpComponent",
  methods: {
    doneButtonClicked() {
      this.$emit("doneClicked");
    },
  },
};
</script>
