<template>
  <div class="overflow-y-auto flex align-top justify-center max-h-screen" @click.stop>
    <img :src="assetSource" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      assetSource: "app/assetSource",
    }),
  },
};
</script>
