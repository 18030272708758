<template>
  <div class="relative">
    <span
      @mouseenter="showTooltip"
      @mouseleave="hideTooltip"
      @click="showTooltip"
      class="hidden group-hover:flex hover:border-primary items-center justify-center rounded-full text-[0.625rem] font-bold cursor-pointer"
      :class="size">
      ?
    </span>
    <div
      v-if="showTooltipBoolean"
      class="tooltip-content bg-cwhite text-dark text-sm px-4 py-2 rounded-lg z-10">
      {{ tooltip }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tooltip: String,
    size: {
      type: String,
      default: "h-6 w-6",
    },
  },
  data() {
    return {
      showTooltipBoolean: false,
    };
  },
  methods: {
    showTooltip() {
      this.showTooltipBoolean = true;
    },
    hideTooltip() {
      this.showTooltipBoolean = false;
    },
  },
};
</script>

<style scoped>
.tooltip-content {
  white-space: normal;
  word-wrap: break-word;
  position: absolute;
  top: 100%; /* Position below the button */
  left: 0;
  transform: translateX(-90%);
  margin-top: 0.5rem; /* Add some space between button and tooltip */
  background-color: white;
  min-width: 10rem; /* Set a minimum width */
  max-width: 30vw; /* Increase the maximum width */
}
</style>
