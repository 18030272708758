import { Reference } from "@/store/types/ChatSearchTypes";

export function formatReferencesToDocument(references: any[]): Reference[] {
  if (!references) {
    return [];
  }
  return references.map((reference) => {
    return {
      title: reference.metadata.source_title ?? reference.metadata.title,
      link: reference.metadata.source_uri ?? reference.metadata.uri,
      location: "gcloud",
      metadata: reference.metadata,
      chunks: [reference],
      showDetails: false,
    };
  });
}

export function groupDocumentsByTitle(documents: Reference[]): Reference[] {
  const grouped: { [key: string]: Reference } = {};

  documents.forEach((document) => {
    const title = document.title;

    if (!grouped[title]) {
      grouped[title] = {
        chunks: [],
        link: document.link,
        location: document.location,
        metadata: document.metadata,
        showDetails: document.showDetails,
        title: document.title,
      };
    }

    grouped[title].chunks.push(...document.chunks);
  });

  return Object.values(grouped);
}

export function formatToUniqueReferences(references: Reference[]) {
  if (!references) {
    return [];
  }
  // Create a Map to hold unique references based on source_title
  const uniqueReferencesMap = new Map();

  references.forEach((reference) => {
    const sourceTitle = reference.metadata.source_title;
    // Add reference to the map if the source_title is not already present
    if (!uniqueReferencesMap.has(sourceTitle)) {
      uniqueReferencesMap.set(sourceTitle, reference);
    }
  });

  // Convert the Map back to an array of unique references
  const uniqueReferences = Array.from(uniqueReferencesMap.values());

  return uniqueReferences;
}

export function isValidChatSearchChat(obj: any) {
  return (
    typeof obj === "object" &&
    typeof obj.initialQuestion === "string" &&
    typeof obj.currentlyAnsweringAll === "boolean" &&
    typeof obj.conversationsWithKnowledgeAssets === "object" &&
    obj.conversationsWithKnowledgeAssets !== null &&
    Object.values(obj.conversationsWithKnowledgeAssets).every(isValidConversationWithKnowledgeAsset)
  );
}

export function isValidConversationWithKnowledgeAsset(obj: any) {
  return (
    (typeof obj === "object" &&
      typeof obj.id === "string" &&
      Array.isArray(obj.conversation) &&
      typeof obj.currentlyAnswering === "boolean" &&
      typeof obj.stopped === "boolean" &&
      obj.lock !== undefined &&
      typeof obj.visibility === "boolean" &&
      typeof obj.followUpQuestion === "string" &&
      (obj.followUpState === "nothing-clicked" ||
        obj.followUpState === "follow-up-question-clicked" ||
        obj.followUpState === "generate-button-clicked") &&
      obj.eventSource === undefined) ||
    obj.eventSource instanceof EventSource ||
    typeof obj.eventSource === "object"
  );
}
