import authenticatedAxios from "@/helpers/axios-wrapper";
import getEndpoint from "@/helpers/endpoints";

const state = {
  changelog: "",
  changelogHasBeenDisplayed: false,
  latestTag: "",
};

const getters = {
  changelog: (state) => state.changelog,
  changelogHasBeenDisplayed: (state) => state.changelogHasBeenDisplayed,
  latestTag: (state) => state.latestTag,
};

const mutations = {
  setChangelog: (state, changelog) => {
    state.changelog = changelog;
  },
  setLatestTag: (state, latestTag) => {
    state.latestTag = latestTag;
  },
  setChangeLogHasBeenDisplayed: (state, changelogHasBeenDisplayed) => {
    state.changelogHasBeenDisplayed = changelogHasBeenDisplayed;
  },
  persist: (state) => {
    localStorage.setItem(
      "changelogHasBeenDisplayed",
      (state.changelogHasBeenDisplayed ? 1 : 0).toString()
    );
    localStorage.setItem("latestTag", state.latestTag);
  },
  loadCache: (state) => {
    state.changelogHasBeenDisplayed = !!parseInt(localStorage.getItem("changelogHasBeenDisplayed"));
    state.latestTag = localStorage.getItem("latestTag");
  },
};

const actions = {
  async fetch({ commit, getters }) {
    const { data } = await authenticatedAxios.get(getEndpoint("/api/changelog"), {
      timeout: 120000,
    });
    commit("setChangelog", data.changelog);
    if (data.latest_tag !== getters.latestTag) {
      commit("setChangeLogHasBeenDisplayed", false);
      commit("setLatestTag", data.latest_tag);
      commit("persist");
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
