export function isBackendURL(url) {
  return url.includes("/api");
}

export function openNewWindow(url) {
  return new Promise((resolve) => {
    const newWindow = window.open(url);
    newWindow.onload = function () {
      newWindow.close();
      resolve("Window loaded");
    };
  });
}

export function tokenNeedsRefresh(url) {
  // check if there is a token for the given url
  if (!sessionStorage["__Host-GCP_IAP_AUTH_TOKEN_FOR_" + url]) return true;
  // check if the given token is valid
  return (
    parseInt(sessionStorage["__Host-GCP_IAP_AUTH_TOKEN_FOR_" + url]) +
      1000 * 1550 <
    new Date().getTime()
  );
}
