import authenticatedAxios from "@/helpers/axios-wrapper";
import getEndpoint from "@/helpers/endpoints";
import store from "@/store";
import { useToast } from "vue-toastification";
const state = {
  displayChatOptions: false,
  displayDocument: null,
  displayPage: 1,
  displayLoadingOverlay: false,
  displayChangelog: false,
  displayChatSidebar: false,
  displaySidebar: false,
  displayChatSearchSidebar: false,
  displayAsset: false,
  displayFileList: false,
  assetSource: false,
};

const getters = {
  displayChatOptions: (state) => state.displayChatOptions,
  showDocument: (state) => !!state.displayDocument,
  showFileList: (state) => state.displayFileList,
  displayLoadingOverlay: (state) => state.displayLoadingOverlay,
  displayDocument: (state) => state.displayDocument,
  displayChangelog: (state) => state.displayChangelog,
  displayPage: (state) => parseInt(state.displayPage),
  displayChatSidebar: (state) => state.displayChatSidebar,
  displaySidebar: (state) => state.displaySidebar,
  displayChatSearchSidebar: (state) => state.displayChatSearchSidebar,
  displayAsset: (state) => state.displayAsset,
  assetSource: (state) => state.assetSource,
};

const mutations = {
  setDisplayAsset: (state, value) => {
    state.displayAsset = value;
  },
  setAssetSource: (state, value) => {
    state.assetSource = value;
  },
  showChatOptions: (state) => {
    state.displayChatOptions = true;
  },
  hideChatOptions: (state) => {
    state.displayChatOptions = false;
  },
  setDisplayDocument: (state, value) => {
    state.displayDocument = value;
  },
  setDisplayFileList: (state, conversationItem) => {
    state.displayFileList = conversationItem;
  },
  setDisplayPage: (state, page) => {
    state.displayPage = page;
  },
  showLoadingOverlay: (state) => {
    state.displayLoadingOverlay = true;
  },
  hideLoadingOverlay: (state) => {
    state.displayLoadingOverlay = false;
  },
  hidePDF: (state) => {
    state.displayDocument = null;
  },
  showChangelog: (state) => {
    state.displayChangelog = true;
    store.commit("changelog/setChangeLogHasBeenDisplayed", true);
    store.commit("changelog/persist");
  },
  hideChangelog: (state) => {
    state.displayChangelog = false;
  },
  setDisplayChatSidebar: (state, value) => {
    state.displayChatSidebar = value;
  },
  setDisplaySidebar: (state, value) => {
    state.displaySidebar = value;
  },
  setDisplayChatSearchSidebar: (state, value) => {
    state.displayChatSearchSidebar = value;
  },
};

const actions = {
  warn: (context, { message, duration = 5000 }) => {
    const toast = useToast();
    toast.warning(message, {
      timeout: duration,
    });
  },
  download({ rootGetters }, { location, link }) {
    const encodedLink = encodeURIComponent(link);
    authenticatedAxios
      .get(
        getEndpoint(
          "/api/core/file" +
            (location === "firebase" ? "?mode=firebase" : "?mode=gcloud") +
            `&filePath=${encodedLink}`
        ),
        {
          timeout: 120000,
          headers: {
            "Content-Type": "multipart/form-data",
            "Genow-Authorization": `Bearer ${rootGetters["auth/user"].stsTokenManager.accessToken}`,
          },
        }
      )
      .then(({ data }) => {
        const linkSource = data;
        const downloadLink = document.createElement("a");
        const fileName = link.replace(/^.*[\\/]/, "");
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
