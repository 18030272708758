<template>
  <div class="flex flex-shrink-0 w-1/4 h-full text-dark justify-between bg-slate-100">
    <button class="xl:hidden flex flex-col items-center justify-center">
      <font-awesome-icon
        :icon="['fas', 'chevron-up']"
        v-if="displayChatSidebar"
        class="text-3xl rotate-90 cursor-pointer hover:text-slate-500"
        @click="setDisplayChatSidebar(false)" />
    </button>
    <aside id="actualSidebar" class="flex-grow flex-col w-full space-y-8 px-3 py-6">
      <div>
        <Transition name="fade">
          <LLMSelection></LLMSelection>
        </Transition>
      </div>
    </aside>
  </div>
</template>
<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { mapMutations, mapGetters } from "vuex";
import LLMSelection from "../../components/LLMSelection";

export default {
  name: "ChatSidebar",
  components: {
    "font-awesome-icon": FontAwesomeIcon,
    LLMSelection,
  },
  computed: {
    ...mapGetters({
      displayChatSidebar: "app/displayChatSidebar",
    }),
  },
  methods: {
    ...mapMutations({
      setDisplayChatSidebar: "app/setDisplayChatSidebar",
    }),
  },
};
</script>
