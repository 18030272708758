import config from "@/customer_configs_flags/config";
function getEndpoint(endpoint) {
  if (config.VUE_APP_ENV === "production") {
    endpoint = endpoint.split("/");
    endpoint.splice(0, 2);
    endpoint = endpoint.join("/");
    endpoint = `https://${config.VUE_APP_BACKEND_URL_PATH}/${endpoint}`;
  } else {
    endpoint = window.location.origin + endpoint;
  }
  return endpoint;
}

export default getEndpoint;
