<template>
  <div class="rounded-lg p-8 bg-white">
    <div class="flex justify-between">
      <h1 class="text-3xl">Update {{ latestTag }}</h1>
      <font-awesome-icon
        icon="fa-solid fa-x"
        class="justify-self-end text-xl cursor-pointer hover:text-red-500"
        @click="hideChangelog"></font-awesome-icon>
    </div>

    <Markdown ref="markdownContent" class="w-full markdown" :source="versionContent" />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import Markdown from "vue3-markdown-it";

export default {
  components: {
    Markdown,
  },
  computed: {
    ...mapGetters({
      changelog: "changelog/changelog",
      latestTag: "changelog/latestTag",
    }),
    versionContent() {
      const versionExtractionRegex = /v?(\d+\.\d+(\.\d+)?)/;
      const versionMatch = this.latestTag.match(versionExtractionRegex);

      if (versionMatch) {
        const version = versionMatch[1];
        const versionRegex = new RegExp(
          `## ${version.replace(/\./g, "\\.")}([\\s\\S]*?)(?=^## \\d|^$)`,
          "gm"
        );
        const match = versionRegex.exec(this.changelog);

        if (match) {
          // Return the section without the version header
          return match[1].trim();
        } else {
          this.hideChangelog();
          return "";
        }
      } else {
        this.hideChangelog();
        return "";
      }
    },
  },
  methods: {
    ...mapMutations({
      hideChangelog: "app/hideChangelog",
    }),
  },
};
</script>
<style>
.markdown li {
  list-style-type: disc;
}
</style>
