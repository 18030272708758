import { createI18n } from "vue-i18n";
import de from "../../locales/de.json";
import en from "../../locales/en.json";
import config from "@/customer_configs_flags/config";
import enCustom from "../../locales/enCustom.json";
import deCustom from "../../locales/deCustom.json";

const defaultLocale = config.defaultLocale;
const locale =
  localStorage.locale && ["de", "en"].includes(localStorage.locale)
    ? localStorage.locale
    : defaultLocale;
const i18n = createI18n({
  legacy: false,
  locale: locale,
  fallbackLocale: defaultLocale,
  messages: {
    en,
    de,
  },
});

i18n.global.mergeLocaleMessage("en", enCustom);
i18n.global.mergeLocaleMessage("de", deCustom);

export default i18n;
