<template>
  <div class="animate-pulse">
    <div class="flex text-slate-400">
      <span>{{ t("loading_skeleton.generating_answer") }}</span>
    </div>
    <div class="flex-1 space-y-3 py-1 mt-2">
      <div v-for="(row, index) in rows" :key="index" class="flex space-x-2">
        <div
          v-for="(block, bIndex) in row.blocks"
          :key="`block-${index}-${bIndex}`"
          :class="`bg-slate-200 rounded-3xl transition-opacity duration-700 opacity-${block.opacity}`"
          :style="{ width: block.width + '%' }"
          class="h-5"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";

export default {
  name: "LoadingSkeleton",
  setup() {
    const { t } = useI18n();
    return { t };
  },
  data() {
    return {
      rows: [],
    };
  },
  mounted() {
    this.addRow();
    const intervalTime = 10000 / 7;
    let interval = setInterval(() => {
      if (this.rows.length < 7) {
        this.addRow();
      } else {
        clearInterval(interval);
      }
    }, intervalTime);
  },
  methods: {
    addRow() {
      // Generiert eine zufällige Anzahl und Breite von Blöcken für die Zeile
      const numberOfBlocks = Math.floor(Math.random() * 3) + 1; // Zwischen 1 und 3 Blöcken
      const blocks = [];
      for (let i = 0; i < numberOfBlocks; i++) {
        blocks.push({
          width: 20 + Math.random() * 60, // Zufällige Breite zwischen 20% und 80%
          opacity: 0, // Beginnt unsichtbar, für Fade-In-Effekt
        });
      }
      // Fügt die neue Zeile zum Array hinzu
      this.rows.push({ blocks });
      // Fügt einen kurzen Verzug hinzu, bevor die Opazität erhöht wird, um den Fade-In-Effekt zu ermöglichen
      setTimeout(() => {
        blocks.forEach((block) => (block.opacity = 100)); // Fade-In zu voller Opazität
      }, 50);
    },
  },
};
</script>

<style>
/* Füge hier deine zusätzlichen Styles hinzu, falls nötig */
</style>
