import { POSITION, useToast } from "vue-toastification";
import axios, { AxiosError } from "axios";
import { isBackendURL, openNewWindow, tokenNeedsRefresh } from "./iap-helpers";

import frontendConfig from "@/customer_configs_flags/config";
import store from "../store";

const axiosOptions = {
  timeout: 20000,
  withCredentials: true,
};

// create custom axiosInstance
const authenticatedAxios = axios.create(axiosOptions);

// create custom Options for the toasts
const customOptions = {
  // timeout of 20 seconds
  timeout: 20000,
  position: POSITION.TOP_CENTER,
  closeOnClick: true,
  pauseOnFocusLoss: false,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.75,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  icon: true,
  withCredentials: true,
};

const toast = useToast();

authenticatedAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error(error);
    const message = error.message;
    if (error instanceof AxiosError && error.response) {
      const status = error.response.status;
      const reason = error.response?.data?.message ?? "";

      if (status === 401 || status === 403) {
        store.dispatch("auth/signout", true);
      }
      toast.error(message + (reason.length ? ".  Reason: " + reason : ""), customOptions);
    } else if (error.request) {
      toast.error(
        "A request was made but there was no response from the server. Error Message: " + message,
        customOptions
      );
    } else {
      toast.error("An unexpected error occurred. Error Message: " + message, customOptions);
    }
    throw error;
  }
);

authenticatedAxios.interceptors.request.use(async (config) => {
  const urlObject = new URL(config.url);
  const url = urlObject.origin + urlObject.pathname;

  if (frontendConfig.enableIAPCookies && isBackendURL(url) && tokenNeedsRefresh(url)) {
    urlObject.searchParams.append("iap-authentication", "true");
    await openNewWindow(urlObject.toString());

    sessionStorage["__Host-GCP_IAP_AUTH_TOKEN_FOR_" + url] = new Date().getTime();
  }

  config.headers[
    "Genow-Authorization"
  ] = `Bearer ${store.getters["auth/user"]?.stsTokenManager.accessToken}`;

  if (!config.headers["Content-Type"]) {
    config.headers["Content-Type"] = "application/json";
  }
  if (!config.timeout) {
    config.timeout = 120000;
  }

  return config;
});

export default authenticatedAxios;
