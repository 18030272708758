<template>
  <aside
    class="flex flex-shrink-0 relative custom-group w-1/4 h-full py-2 text-dark justify-between bg-slate-100 overflow-y-auto">
    <button class="absolute left-0 -translate-x-4 -translate-y-1/2 top-1/2 z-20 p-2">
      <font-awesome-icon
        :icon="['fas', 'chevron-up']"
        v-if="displayChatSearchSidebar"
        class="text-3xl rotate-90 cursor-pointer hover:text-slate-500 relative hidden custom-group-hover"
        @click="setDisplayChatSearchSidebar(false)" />
    </button>
    <div class="flex flex-col flex-grow w-full pl-5 pr-3 mt-8 space-y-8">
      <Transition name="fade">
        <LLMSelection storeModule="chatSearch"></LLMSelection>
      </Transition>
    </div>
  </aside>
</template>
<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { mapGetters, mapMutations } from "vuex";
import LLMSelection from "../../components/LLMSelection";

export default {
  name: "ChatSearchSidebar",
  components: {
    "font-awesome-icon": FontAwesomeIcon,
    LLMSelection,
  },
  computed: {
    ...mapGetters({
      displayChatSearchSidebar: "app/displayChatSearchSidebar",
    }),
  },
  methods: {
    ...mapMutations({ setDisplayChatSearchSidebar: "app/setDisplayChatSearchSidebar" }),
  },
};
</script>
<style scoped>
.custom-group:hover .custom-group-hover {
  display: block;
}
</style>
