<template>
  <div class="grid grid-cols-2 gap-2 text-sm text-gray-600 mb-2 px-11 max-w-[1200px] w-[85%]">
    <div
      class="py-2 px-4 border hover:border-primary rounded-md group cursor-pointer"
      :key="index + '_example_question'"
      v-for="(question, index) in chatHistory[chatHistory.length - 1]?.followQuestions ?? []"
      @dblclick="askFollowUpQuestion(question)"
      @click="setNextQuestion(question)"
      v-tooltip.bottom="'Click once to edit, or double-click to send it instantly.'">
      <span class="font-semibold text-xs block text-gray-400"> Follow up {{ index + 1 }} </span>
      <span class="typewriter font-medium text-sm group-hover:text-primary">
        {{ question }}
      </span>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  computed: {
    ...mapGetters({
      chatHistory: "chat/chatHistory",
    }),
  },
  methods: {
    ...mapActions({
      askQuestion: "chat/askQuestion",
    }),
    ...mapMutations({
      setNextQuestion: "chat/setNextQuestion",
    }),
    askFollowUpQuestion(request) {
      this.askQuestion({ question: request, locale: this.$i18n.locale });
      // Scroll to bottom after short timeout
      setTimeout(() => {
        let element = document.getElementById("bottom");
        element.scrollIntoView({ behavior: "smooth" });
      }, 100);
    },
  },
};
</script>
