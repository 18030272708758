<template>
  <div class="mt-5 hover:bg-slate-200 rounded-md pb-1">
    <div
      @click="toggleSection"
      @mouseover="isHovered = true"
      @mouseleave="isHovered = false"
      :class="[
        'flex items-center justify-between px-4 py-2 cursor-pointer',
        { hovered: isHovered },
      ]">
      <div class="flex items-center">
        <font-awesome-icon
          :icon="['fas', isOpen ? 'caret-up' : 'caret-right']"
          class="w-4 h-4 mr-2 hover:bg-slate-300 rounded-2xl hover:border-slate-400 p-1" />

        <span class="ml-1 text-sm font-medium text-gray-600">{{ title }}</span>
      </div>

      <div
        v-if="icon && iconAction"
        class="px-3 hover:bg-slate-300 rounded-2xl hover:border-slate-400 p-1"
        @click.stop="executeAction">
        <font-awesome-icon
          :icon="icon"
          class="w-4 h-4 cursor-pointer text-gray-600 hover:text-gray-800" />
      </div>
    </div>

    <transition name="fade">
      <div
        v-show="isOpen"
        class="overflow-auto section-content"
        :style="{ maxHeight: maxHeight + 'px' }">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "CollapsibleSection",
  props: {
    title: {
      type: String,
      required: true,
    },
    isOpenDefault: {
      type: Boolean,
      default: true,
    },
    maxHeight: {
      type: Number,
      default: 300,
    },
    icon: {
      type: Array,
      default: () => ["fas", "plus"],
    },
    iconAction: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      isOpen: this.isOpenDefault,
      isHovered: false,
    };
  },
  methods: {
    toggleSection() {
      this.isOpen = !this.isOpen;
    },
    executeAction() {
      if (this.iconAction) {
        this.iconAction();
      }
    },
  },
};
</script>

<style scoped>
.section-content {
  transition: max-height 0.3s ease-in-out;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
